import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Link,
  Grid,
  // Table, TableBody, TableRow, TableCell,
  IconButton,
  Box,
  Button,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditTestResult from "../../AdminEdits/EditTestResult";
import HeavyIonDetailsModal from "./HeavyIonDetailsModal"; // Adjust the import path as necessary

const PartDetailsTestResults = ({ testResults, isAdmin }) => {
  const [open, setOpen] = useState(false);
  const [selectedTestResult, setSelectedTestResult] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedDatasourceTitle, setSelectedDatasourceTitle] = useState("");
  const [selectedTestResultName, setSelectedTestResultName] = useState("");

  const onEdit = (testResult) => {
    console.log("Edit test result:", testResult["Test Name"]);
    setSelectedTestResult(testResult["Test Name"]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTestResult(null);
  };

  const handleDetailsOpen = (datasourceTitle, testResultName) => {
    setSelectedDatasourceTitle(datasourceTitle);
    setSelectedTestResultName(testResultName);
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  return (
    <div>
      {testResults.map((testResult, index) => (
        <Grid item xs={12} key={`test-result-${index}`}>
          <Box
            variant="outlined"
            sx={{
              margin: 2,
              backgroundColor: "white",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              alignItems: "center",
              borderRadius: "16px",
              padding: "16px 16px 16px 16px",
            }}
          >
            <CardContent sx={{ py: 0 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: 22,
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 500,
                  }}
                  variant="subtitle1"
                >
                  {" "}
                  {testResult["Test Name"] || "TBD"}{" "}
                </Typography>
                {isAdmin && (
                  <IconButton
                    onClick={() => onEdit(testResult)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              {testResult["Restricted"] ? (
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: 16,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                    }}
                    variant="body2"
                  >
                    {testResult["Restricted Description"] ||
                      "Restricted information"}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: 16,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                    }}
                    variant="body2"
                  >
                    This result is available for purchase.
                  </Typography>
                </div>
              ) : (
                // <Table size="small">
                //     <TableBody>
                //         <TableRow>
                //             <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><strong>Test Type</strong></TableCell>
                //             <TableCell>{testResult["Test Type"] || 'TBD'}</TableCell>
                //         </TableRow>
                //         <TableRow>
                //             <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><strong>Summary</strong></TableCell>
                //             <TableCell>{testResult["Open AI Test Results Summary"] || 'TBD'}</TableCell>
                //         </TableRow>
                //         <TableRow>
                //             <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><strong>Tested Limit</strong></TableCell>
                //             <TableCell>{testResult["Max Passing Value"] || 'TBD'}</TableCell>
                //         </TableRow>
                //         <TableRow>
                //             <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><strong>Source Title</strong></TableCell>
                //             <TableCell>{testResult["DataSource Title"] || 'TBD'}</TableCell>
                //         </TableRow>
                //         <TableRow>
                //             <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><strong>Source Link</strong></TableCell>
                //             <TableCell><Link href={testResult["Datasource URL"] || '#'} target="_blank">{testResult["Datasource URL"] || 'TBD'}</Link></TableCell>
                //         </TableRow>
                //     </TableBody>
                // </Table>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        display: "flex",
                        gap: "5px",
                        minWidth: "110px",
                        textAlign: "left",
                      }}
                      component="th"
                    >
                      Test Type
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "#637381",
                        display: "flex",
                      }}
                    >
                      {testResult["Test Type"] || "TBD"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        display: "flex",
                        gap: "5px",
                        minWidth: "110px",
                        textAlign: "left",
                      }}
                      component="th"
                    >
                      Summary
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "#637381",
                        display: "flex",
                      }}
                    >
                      {testResult["Open AI Test Results Summary"] || "TBD"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        display: "flex",
                        gap: "5px",
                        minWidth: "110px",
                        textAlign: "left",
                      }}
                      component="th"
                    >
                      Tested Limit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "#637381",
                        display: "flex",
                      }}
                    >
                      {testResult["Max Passing Value"] || "TBD"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        display: "flex",
                        gap: "5px",
                        minWidth: "110px",
                        textAlign: "left",
                      }}
                      component="th"
                    >
                      Source Title
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "#637381",
                        display: "flex",
                      }}
                    >
                      {testResult["DataSource Title"] || "TBD"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        display: "flex",
                        gap: "5px",
                        minWidth: "110px",
                        textAlign: "left",
                      }}
                      component="th"
                    >
                      Source Link
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: 14,
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "#637381",
                        display: "flex",
                      }}
                    >
                      <Link
                        href={testResult["Datasource URL"] || "#"}
                        target="_blank"
                      >
                        {testResult["Datasource URL"] || "TBD"}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                mt: 3,
                mr: 2,
              }}
            >
              <Button
                sx={{
                  mt: 2,
                  mb: 3,
                  borderRadius: "12px",
                  backgroundColor: "#F26E21",
                  border: "1px solid #F26E21",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                  px: 3,
                  py: 1,
                  cursor: "pointer",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#F26E21",
                    color: "#fff",
                  },
                  "&:active": {
                    backgroundColor: "#F26E21",
                    color: "#fff",
                    borderColor: "#F26E21",
                  },
                  "&.Mui-focusVisible": {
                    backgroundColor: "#F26E21",
                    color: "#fff",
                    borderColor: "#F26E21",
                  },
                }}
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDetailsOpen(
                    testResult["DataSource Title"],
                    testResult["Test Name"]
                  )
                }
                id={`details-button-${index}`}
              >
                More Experiment Details
              </Button>
            </Box>
          </Box>
        </Grid>
      ))}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-test-result-modal"
        aria-describedby="edit-test-result-form"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
             
          }}
        >
          {selectedTestResult && (
            <EditTestResult
              testResultName={selectedTestResult}
              onClose={handleClose}
            />
          )}
        </Box>
      </Modal>

      <HeavyIonDetailsModal
        open={detailsOpen}
        handleClose={handleDetailsClose}
        datasourceTitle={selectedDatasourceTitle}
        testResultName={selectedTestResultName}
      />
    </div>
  );
};

export default PartDetailsTestResults;
