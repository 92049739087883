import React, { useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";

const EarthGlobe = () => {
  const globeEl = useRef();

  const scale = 1.2;

  useEffect(() => {
    const globe = globeEl.current;
    globe.controls().enableZoom = false;

    // Auto-rotate
    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.35;

    const waitForGlobeLoaded = setInterval(() => {
      if (globe.scene()) {
        scaleGlobe();
        setGlobeMaterialTransparent();

        clearInterval(waitForGlobeLoaded);
      }
    }, 100);

    // Function to scale the globe
    const scaleGlobe = () => {
      globe.scene().traverse((object) => {
        if (object.isMesh && object.geometry.type === "SphereGeometry") {
          object.scale.set(scale, scale, scale);
        }
      });
    };

    // Adding transparency to the globe material
    const setGlobeMaterialTransparent = () => {
      globe.scene().traverse((object) => {
        if (object.isMesh && object.material) {
          if (
            object.material.map &&
            object.material.map.image &&
            object.material.map.image.src.includes("earth-blue-marble.jpg")
          ) {
            object.material.transparent = true;
            object.material.opacity = 0.7;
          }
        }
      });
    };

    // Add clouds sphere
    const CLOUDS_IMG_URL = "/images/clouds_2.png"; // from https://github.com/turban/webgl-earth
    const CLOUDS_ALT = 0.004;
    const CLOUDS_ROTATION_SPEED = -0.006; // deg/frame

    new THREE.TextureLoader().load(CLOUDS_IMG_URL, (cloudsTexture) => {
      const clouds = new THREE.Mesh(
        new THREE.SphereGeometry(
          globe.getGlobeRadius() * (1 + CLOUDS_ALT),
          75,
          75
        ),
        new THREE.MeshPhongMaterial({ map: cloudsTexture, transparent: true })
      );
      clouds.scale.set(scale, scale, scale); // Scale the clouds as well
      globe.scene().add(clouds);

      (function rotateClouds() {
        clouds.rotation.y += (CLOUDS_ROTATION_SPEED * Math.PI) / 180;
        requestAnimationFrame(rotateClouds);
      })();
    });


  }, [scale]);

  return (
    <Globe
      ref={globeEl}
      animateIn={true}
      width={500}
      height={500}
      backgroundColor="rgba(0,0,0,0)"
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
    />
  );
};

export default EarthGlobe;