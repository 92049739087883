import React, { useState } from "react";
import AddFullPart from "./AddFullPart";
import AddDigikeyPart from "./AddDigikeyPart";
import AddAliasForm from "./AddAliasForm";
import AddManufacturerForm from "./AddManufacturerForm";
import AddDataSourceForm from "./AddDataSourceForm";
import AddTestResultForm from "./AddTestResultForm";
import AddUserToTestResultForm from "./AddUserToTestResultForm";
import EditUserForm from "../AdminEdits/EditUserForm";
import NucleonWrapper from "../Wrapper/NucleonWrapper";
import PageHeader from "../Dashboard/PageHeader/page-header";
import { Box, Select, MenuItem } from "@mui/material";

const Admin = () => {
  const [activeForm, setActiveForm] = useState("AddDigikeyPart");

  const handleFormChange = (event) => {
    setActiveForm(event.target.value);
  };


  return (
    <Box sx={{ padding: "0px" }}>
      <PageHeader
        title=" Admin Panel"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: " Admin Panel", href: "/dashboard/bill-of-materials" },
        ]}
        showButton={false}
        sx={{ mb: "20px" }}
      />

      <Select
        value={activeForm}
        onChange={handleFormChange}
        sx={{
          width: "fit-content",
          marginTop: "30px",
          marginBottom: "30px",
          borderRadius: "12px",
          color: "#000000",
          textAlign: "center",
          fontSize: "14px",
          paddingRight: "32px",
          paddingLeft: "16px",
          minWidth: "200px",
          height: "48px",
          cursor: "pointer",
          fontFamily: "'Poppins', sans-serif",
          textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          transition: "background-color 0.3s, box-shadow 0.3s",
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          border: "2px solid #f26e21",
          outline: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 'none',
          },
        }}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
            },
            "& .MuiMenuItem-root:hover": {
            },
          },
        }}
      >
        <MenuItem value="AddFullPart">Add Full Part</MenuItem>
        <MenuItem value="AddDigikeyPart">Add Digikey Part</MenuItem>
        <MenuItem value="AddAliasForm">Add Alias to Manufacturer</MenuItem>
        <MenuItem value="AddManufacturerForm">Add Manufacturer</MenuItem>
        <MenuItem value="AddDataSourceForm">Add Data Source</MenuItem>
        <MenuItem value="AddTestResultForm">Add Test Result</MenuItem>
        <MenuItem value="AddUserToTestResult">Add User to Test Result</MenuItem>
        <MenuItem value="EditUserForm">Edit User</MenuItem>
      </Select>

      <NucleonWrapper>
        {activeForm === "AddFullPart" && <AddFullPart />}
        {activeForm === "AddDigikeyPart" && <AddDigikeyPart />}
        {activeForm === "AddAliasForm" && <AddAliasForm />}
        {activeForm === "AddManufacturerForm" && <AddManufacturerForm />}
        {activeForm === "AddDataSourceForm" && <AddDataSourceForm />}
        {activeForm === "AddTestResultForm" && <AddTestResultForm />}
        {activeForm === "AddUserToTestResult" && <AddUserToTestResultForm />}
        {activeForm === "EditUserForm" && <EditUserForm />}
      </NucleonWrapper>
    </Box>
  );
};

export default Admin;