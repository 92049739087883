import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const NucleonWrapper = ({ children, sx = {}, ...otherProps }) => {
  const defaultSx = {
    backgroundColor: "white",
    boxShadow:
      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    alignItems: "center",
    borderRadius: "16px",
    padding: "16px 16px 16px 24px",
  };

  return (
    <Box sx={{ ...defaultSx, ...sx }} {...otherProps}>
      {children}
    </Box>
  );
};

NucleonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default NucleonWrapper;
