import React, { useState } from 'react';
import { Typography, Grid, TextField, Box } from '@mui/material';
import SectionHeader from '../SectionHeader/section-header';
import AnimateButton from '../Button/AnimateButton';
import { CONTACT_INFO } from '../../constants/about-us';

const ContactForm = () => {
  const textFieldStyles = {
    fontFamily: "'Poppins', sans-serif",
    '& .MuiOutlinedInput-root': {
      color: 'black',
      fontFamily: "'Poppins', sans-serif",
      '& fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
        fontFamily: "'Poppins', sans-serif",
      },
      '&:hover fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
        borderBottom: '1px solid gray',
        borderRadius: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/public/submit_contact_form`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        alert('Form submitted successfully!');
        console.log('Server Response:', responseData);
      } else {
        throw new Error(responseData.message || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <Box
      sx={{
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '100px',
      }}
    >
      <SectionHeader
        title="Contact Us"
        subtitle="We Would Love To Hear From You."
      />

      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 5,
          mt: 5,
          '@media (max-width:1270px)': {
            justifyContent: 'flex-start',
            flexDirection: 'column',
          },
          '@media (max-width:880px)': {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            backgroundColor: '#ffffff',
            boxShadow: '0px 10px 30px rgba(214, 215, 216, 0.57)',
            padding: '50px 37px',
            borderRadius: '8px',
            width: '100%',
            maxWidth: { md: '100%', lg: '350px' },
            zIndex: 2,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-12px',
              left: '-12px',
              width: '100%',
              height: '100%',
              backgroundColor: '#ffffff',
              boxShadow: '0px 10px 30px rgba(214, 215, 216, 0.57)',
              borderRadius: '8px',
              zIndex: -1,
            },
          }}
        >
          <Box sx={{ width: '98%' }}>
            {CONTACT_INFO.map((info, index) => (
              <React.Fragment key={info.title}>
                <Box sx={{ display: 'flex', mb: 3 }}>
                  <Box sx={{ mr: 2 }}>{info.icon}</Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      gutterBottom
                    >
                      {info.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      variant="body1"
                      color="textSecondary"
                    >
                      {info.content.split('\n').map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  </Box>
                </Box>
                {index < CONTACT_INFO.length - 1 && (
                  <Box
                    sx={{
                      borderTop: '2px dotted rgba(0, 0, 0, 0.2)',
                      my: 3,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{ maxWidth: { sm: '95%', md: '1280px', lg: '1580px' } }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: {
                      color: '#3B3664',
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  required
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: {
                      color: '#3B3664',
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: {
                      color: '#3B3664',
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    style: {
                      color: '#3B3664',
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} mb={3}>
                <TextField
                  InputLabelProps={{
                    style: {
                      color: '#3B3664',
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <AnimateButton type="submit">Send Message</AnimateButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default ContactForm;