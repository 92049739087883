import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../assets/logo16x16.png";

// Keyframes for the animations
const orbit1 = keyframes`
  0% {
    transform: rotate(0deg) translateX(130px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(130px) rotate(-360deg);
  }
`;

const orbit2 = keyframes`
  0% {
    transform: rotate(0deg) translateX(80px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(80px) rotate(-360deg);
  }
`;

const orbit3 = keyframes`
  0% {
    transform: rotate(0deg) translateX(100px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(100px) rotate(-360deg);
  }
`;

const moveBackground = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
`;

// Styled components
const LoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: #000000;
  background-image: url("https://unpkg.com/three-globe@2.31.1/example/img/night-sky.png");
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${moveBackground} 30s linear infinite;
  z-index: 2000;
`;

const Loader = styled(Box)`
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
`;

const CentralPlanet = styled(Box)`
  width: 60px;
  height: 60px;
  position: absolute;
  background: linear-gradient(145deg, #f26e21, #f9975b);
  box-shadow: inset -5px -5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

const Sphere = styled(Box)`
  width: 20px;
  height: 20px;
  position: absolute;
  background: linear-gradient(145deg, #f26e21, #f9975b);
  box-shadow: inset -5px -5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  &.orbit1 {
    animation: ${orbit1} 3s linear infinite;
  }
  &.orbit2 {
    animation: ${orbit2} 4s linear infinite;
  }
  &.orbit3 {
    animation: ${orbit3} 5s linear infinite;
  }
`;

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px; // Ensure there is appropriate gap between logo and text
`;

const LoaderWithAnimatedDivs = () => (
  <LoaderContainer>
    <LogoContainer>
      <img src={logo} style={{ width: 60, height: 60 }} alt="logo" />
      <Typography
        variant="h4"
        style={{
          color: "#fff",
          textAlign: "center",
          fontFamily: "'Poppins', sans-serif",
          display: "inline-block",
        }}
      >
        Nucleon
      </Typography>
    </LogoContainer>

    <Loader>
      <CentralPlanet />
      <Sphere className="orbit1" />
      <Sphere className="orbit2" />
      <Sphere className="orbit3" />
    </Loader>
  </LoaderContainer>
);

export default LoaderWithAnimatedDivs;
