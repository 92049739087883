// RadiationTestingCard.js

import React from 'react';
import './RadiationTestingCard.css';
import RadiationTestingSubCardComponent from '../RadiationTestingSubCard/RadiationTestingSubCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Example icon for quarterly test campaigns
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Example icon for discounts
import ScienceIcon from '@mui/icons-material/Science'; // Example icon for testing

const RadiationTestingCard = () => {
  return (
    <div className="container-black p-5" id="radiation-testing-card">
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src="cyclotron.jpeg" alt="Relevant Imagery" className="img-fluid" id="radiation-testing-image" style={{ maxWidth: '100%' }} />
        </div>
        <div className="col-md-6 text-start">
          <h2 className="fw-bold bulletPoints-letters-orange mb-4" id="radiation-testing-title">Nucleon Radiation Testing</h2>
          <ul className="bulletPoints-list fs-5 fw-bold" id="radiation-testing-list">
            <li id="radiation-testing-item-1">
              <AccessTimeIcon className="icon" />
              Subscribers have opportunity to participate in quarterly test campaigns
            </li>
            <li id="radiation-testing-item-2">
              <MonetizationOnIcon className="icon" />
              Receive discounts by allowing publication of test data
            </li>
            <li id="radiation-testing-item-3">
              <ScienceIcon className="icon" />
              Leverage our partnerships with multiple facilities for heavy-ion, proton, total-ionizing dose, and displacement damage testing
            </li>
          </ul>
          <RadiationTestingSubCardComponent />
        </div>
      </div>
    </div>
  );
}

export default RadiationTestingCard;