import AboutUs from '../AboutUs/AboutUs';
import './AboutPage.css';
import React from 'react';
// import Header from '../HeaderBar/Header';
// import AboutUS from '../AboutUS/AboutUS';
// import Personnel from '../Personnel/Personnel';
// import FAQs from '../FAQs/FAQs';
// import Copyright from '../Copyright/Copyright';

function AboutPage() {
  return (
    <div className="App" id="about-page">
      {/* <Header />
      <AboutUS />
      <Personnel />
      <FAQs />
      <Copyright /> */}
      <AboutUs />
    </div>
  );
}

export default AboutPage;