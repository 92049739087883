import React from "react";
import { Typography, Box, styled } from "@mui/material";
import PropTypes from "prop-types";

const SectionHeader = ({
  title,
  subtitle,
  subtitleColor,
  subtitleFontSize,
}) => {
  const GradientButton = styled(Typography)({
    background:
      "linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))",
    color: "#F26E21",
    padding: "10px 20px",
    borderRadius: "8px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 600,
    boxShadow: "none",
    overflow: "hidden",
    fontFamily: "'Poppins', sans-serif",
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: { xs: 3, md: 5 },
      }}
    >
      <GradientButton>{title}</GradientButton>
      <Typography
        sx={{
          mt: 2,
          fontSize: subtitleFontSize || {
            xs: "30px",
            sm: "40px",
            md: "3.2rem",
          },
          fontWeight: "600",
          color: subtitleColor || "#3B3664",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleBgColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  titlePadding: PropTypes.string,
  subtitleFontSize: PropTypes.string,
};

export default SectionHeader;
