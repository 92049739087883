import { useState, useEffect } from 'react';

const useFetchUsers = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/users`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setUsers(data);
                } else {
                    throw new Error('Failed to fetch users.');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchUsers();
    }, []);

    return users;
};

export default useFetchUsers;