import React, { useState } from "react";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useFetchAdmin from "../../../hooks/useFetchAdmin";
import { useTheme, useMediaQuery } from "@mui/material";
import { ADMIN_ITEMS, NAV_ITEMS } from "../../../constants/dashboard/sidebar";
import DashboardAppBar from "../AppBar/app-bar";

const drawerWidth = 240;

const PageWrapper = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isAdmin = useFetchAdmin();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isRouterActive = (to) => {
    return location.pathname === to;
  };

  const NavItem = ({ to, icon, text }) => (
    <ListItem
      component={Link}
      to={to}
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
        borderRadius: "12px",
        marginBottom: "6px",
        gap: 2,
        color: isRouterActive(to) ? "#F26E21" : "#637381",
        // opacity: isRouterActive(to) ? 1 : 0.5,
        background: isRouterActive(to)
          ? "linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))"
          : "transparent",
        "&:hover": {
          background:
            "linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))",
          color: "#F26E21",
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, paddingBlock: 0.5 }}>
        {React.cloneElement(icon, {
          sx: { color: isRouterActive(to) ? "#F26E21" : "#637381" },
        })}
      </ListItemIcon>
      <Typography
        variant="body1"
        fontSize={14}
        sx={{ fontWeight: "500", fontFamily: "'Poppins', sans-serif" }}
      >
        {text}
      </Typography>
    </ListItem>
  );

  const drawer = (
    <Box sx={{ backgroundColor: "#fff", flexGrow: 1 }}>
      <Toolbar
        onClick={() => navigate("/dashboard")}
        sx={{
          py: 4,
          gap: 1,
          justifyContent: "center",

          paddingLeft: 0,
          cursor: "pointer",
        }}
      >
        <img
          src={"/NucleonLogoGood.png"}
          alt="Nucleon Logo"
          style={{ width: "200px", height: "auto", maxWidth: "150px" }}
        />
      </Toolbar>

      <List>
        {NAV_ITEMS.map((item) => (
          <NavItem key={item.to} {...item} />
        ))}
        {isAdmin &&
          ADMIN_ITEMS.map((item) => <NavItem key={item.to} {...item} />)}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardAppBar handleToggleSidebar={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          overflowY: "hidden",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowY: "visible",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowY: "visible",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: "32px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflow: "hidden",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default PageWrapper;
