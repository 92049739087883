import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import HeroBackground from "../../assets/space.jpg";
import EarthGlobe from "../EarthGlobe/earth-globe";
import AnimateButton from "../Button/AnimateButton";
import { Link } from "react-router-dom";

const StyledHero = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "",
  textAlign: "left",
  color: "white",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const Hero = () => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledHero>

      <HeroContent sx={{ order: { xs: 2, md: 1 } }}>
        <Box
          sx={{
            maxWidth: { sm: "100%", md: "85%", lg: "90%" },
            alignSelf: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              fontSize: { xs: "2rem", md: "3rem" },
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              mb: { xs: 2, md: 2 },
              maxWidth: "sm",
            }}
          >
            Intuitive Radiation Effects Resource Database
          </Typography>
          <Typography
            variant="h6"
            paragraph
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              maxWidth: "sm",
              mb: { xs: 4, md: 4 },
            }}
          >
            A comprehensive resource for electronic component radiation
            tolerance data
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Link  
            to="/#products"
            > 
            <AnimateButton>
              Explore Nucleon
              </AnimateButton>
              </Link>
          </Box>
        </Box>
      </HeroContent>
      {isMediumUp && (
        <Box
          sx={{
            order: 2,
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EarthGlobe />
        </Box>
      )}
    </StyledHero>
  );
};

export default Hero;
