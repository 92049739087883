import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Box,
} from "@mui/material";
import optionsTestType from "./optionsTestType";
import useFetchMPNs from "../../hooks/useFetchMPNs";
import useFetchDataSources from "../../hooks/useFetchDataSources";
import useAddTestResult from "../../hooks/useAddTestResult";
import { FormContainer, StatusMessage } from "./AdminCss";
import { sanitizeInput } from "../../helper/helper";

const AddTestResultForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    test_type: "",
    description: "",
    restricted: false,
    restrictedDescription: "",
    hidden: false,
  });

  const { addTestResult, statusMessage } = useAddTestResult();

  // Setup autocomplete options for datasources
  const dataSources = useFetchDataSources();
  const [selectedDataSource, setSelectedDataSource] = useState(null);

  // Setup autocomplete options for mpns
  const mpns = useFetchMPNs();
  const [selectedMPN, setSelectedMPN] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? sanitizeInput(checked) : sanitizeInput(value),
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      name: formData.name,
      test_type: formData.test_type,
      description: formData.description,
      restricted: formData.restricted,
      restrictedDescription: formData.restrictedDescription,
      hidden: formData.hidden,
      selectedMPN: selectedMPN,
      selectedDataSource: selectedDataSource,
    };

    addTestResult(payload);
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "10px",
  };

  const inputSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(145, 158, 171, 0.8)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
      borderColor: "rgba(145, 158, 171, 0.2)",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(145 158 171)",
      "&.Mui-focused": {
        color: "black",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
      opacity: 1,
    },
    "& .MuiInputBase-input:focus::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
    },
  };

  const buttonSx = {
    mt: 3,
    mb: 1,
    borderRadius: "12px",
    backgroundColor: "#F26E21",
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    px: 2,
    py: 2,
    minWidth: 200,
    height: "48px",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
  };

  return (
    <FormContainer>
      <Box component="form" onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TextField
          id="test-result-name"
          label="Test Result Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          style={inputStyle}
          sx={inputSx}
        />

        <FormControl fullWidth style={inputStyle} sx={inputSx}>
          <InputLabel id="datasources-test-type-label">Test Type</InputLabel>
          <Select
            labelId="datasources-test-type-label"
            id="datasources-test-type-dropdown"
            name="test_type"
            value={formData.test_type}
            onChange={handleChange}
            fullWidth
          >
            {optionsTestType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          id="datasources-autocomplete"
          options={dataSources}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Sources"
              fullWidth
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedDataSource}
          onChange={(event, newValue) => setSelectedDataSource(newValue)}
          fullWidth
          sx={inputSx}
        />

        <Autocomplete
          id="mpn-autocomplete"
          options={mpns}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="MPN"
              fullWidth
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedMPN}
          onChange={(event, newValue) => setSelectedMPN(newValue)}
          fullWidth
          sx={inputSx}
        />

        <TextField
          id="description"
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          style={inputStyle}
          sx={inputSx}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formData.restricted}
              onChange={handleChange}
              name="restricted"
            />
          }
          label="Restricted"
        />

        {formData.restricted && (
          <TextField
            id="restricted-description"
            label="Restricted Description"
            name="restrictedDescription"
            value={formData.restrictedDescription}
            onChange={handleChange}
            multiline
            rows={2}
            fullWidth
            style={inputStyle}
            sx={inputSx}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={formData.hidden}
              onChange={handleChange}
              name="hidden"
            />
          }
          label="Hidden"
        />

        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
          <Button
            id="submit-button"
            variant="contained"
            color="primary"
            type="submit"
            sx={buttonSx}
          >
            Submit
          </Button>
        </Box>

        {statusMessage && (
          <StatusMessage
            id="status-message"
            style={{
              color: statusMessage.type === "success" ? "green" : "red",
            }}
          >
            {statusMessage.text}
          </StatusMessage>
        )}
      </Box>
    </FormContainer>
  );
};

export default AddTestResultForm;
