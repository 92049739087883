export const FAQS = [
  {
    title: "Why should I use your database?",
    content:
      "The database offers extensive radiation performance information for electrical components. Our database provides curated test information on each part, enabling customers to efficiently search for radiation effects data for the parts they are considering using. In addition, we offer succinct explanations of the outcomes, accompanied by hyperlinks to the actual test findings. In addition, our database offers features such as the Bill of Materials upload and data plots, which enable users to efficiently verify their BOM against our database or locate a specific part using our radiation data plots for parts or manufacturers. Our database enables engineers and companies to efficiently save time and money by promptly accessing past test results for the specific part they are seeking, hence preventing redundant testing. We are continuously incorporating new components into our inventory, including a substantial quantity of parts that currently lack publicly accessible radiation impact data.",
  },
  {
    title: "Can I have a trial or demonstration of the database?",
    content:
      "Yes, you can try out our database by registering for a free trial with limited access to our parts. This is available at the top right of this page. You can also arrange for a live demonstration by emailing info@nucleonspace.com.",
  },
  {
    title: "How much does your database cost?",
    content:
      "We offer 1 year subscriptions for $6,000 per license. This is available for immediate purchase at the Pricing tab. Team subscriptions are available for quote. Please contact info@nucleonspace.com for more information.",
  },
  {
    title: "What type of testing can you do?",
    content:
      "Our testing services include total-ionizing dose (TID), heavy-ion, and proton testing. We offer cross-section measurement, laser and acid-etch decapsulation services.",
  },
  {
    title: "How much do you charge for testing?",
    content:
      "Testing is quoted based on the specific requirements of the customer. Please contact info@nucleonspace.com. We aim to be competitive on price. We can offer turnkey testing of 3 SNs of a part at 2 LETs for ~$10k.",
  },
  {
    title: "How can I add my parts to the database?",
    content:
      "To contribute to our database, please contact Henry at henry@cyclotechnologies.com. You may either provide us with a link to your parts datasheets or provide an excel file including your parts data. If you have a preference, we are also amenable to incorporating your components in alternative manners.",
  },
  {
    title: "Why should I add my parts to your database?",
    content:
      "Adding your items to our database would ensure that potential customers have the most up-to-date information on your components. It is an opportunity to reach out to more customers and show them how your parts stand apart.",
  },
  {
    title: "Who are your users?",
    content:
      "We serve a diverse range of customers, including established aerospace companies, constellation builders, and rising commercial space firms.",
  },
];
