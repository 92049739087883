// MarketplaceCard.js

import React from 'react';
import './MarketplaceCard.css'; // Ensure this CSS file is updated for new styles
import MarketplaceSubCardComponent from '../MarketplaceSubCard/MarketplaceSubCard';
import ListIcon from '@mui/icons-material/List';  // Example icon for list items
import CategoryIcon from '@mui/icons-material/Category';  // Example icon for categories
import UpdateIcon from '@mui/icons-material/Update';  // Example icon for updates
import EmailIcon from '@mui/icons-material/Email'; // Icon for the email link

const MarketplaceCard = () => {
  return (
    <div className="container-o p-5" id="marketplace-card">
      <div className="row">
        {/* Text Section */}
        <div className="col-md-6 col-lg-6">
          <div className="text-start">
            <h2 className="bulletPoints-letters-white fw-bold mb-4" id="marketplace-title">Nucleon Data Marketplace</h2>
            <ul className="bulletPoints-letters-white fs-5 fw-bold mb-2" id="marketplace-list">
              <li id="marketplace-item-1"><ListIcon /> Securely trade radiation effects test reports</li>
              <li id="marketplace-item-2"><CategoryIcon /> Report quality ensured with expert review</li>
              <li id="marketplace-item-3"><UpdateIcon /> Subscription proceeds contribute to database expansion</li>
              <li id="marketplace-item-4">
                <EmailIcon /> For more information, contact &nbsp;{' '}
                <a href="mailto:marketplace@cyclotechnologies.com" className="email-link" id="marketplace-email">
                  marketplace@cyclotechnologies.com
                </a>
              </li>
            </ul>
            <MarketplaceSubCardComponent />
          </div>
        </div> 
        {/* Image Section */}
        <div className="col-md-6 col-lg-6">
          <div className="d-flex justify-content-center align-items-center">
            <img src="data_marketplace_image.jpg" alt="Relevant Imagery" className="img-fluid" id="marketplace-image" style={{ maxWidth: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketplaceCard;