import { useState, useEffect } from 'react';

const useFetchDataSources = () => {
    const [dataSources, setDataSources] = useState([]);

    useEffect(() => {
        const fetchDataSources = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/datasources`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setDataSources(data);
                } else {
                    throw new Error('Failed to fetch Data Sources.');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchDataSources();
    }, []);

    return dataSources;
};

export default useFetchDataSources;