import React from 'react';
import './BlackCard.css'; // Make sure to create this CSS file

const BlackCard = () => {
  return (
    <div className="container-black p-5" id="blackcard">
      <div className="row">
        {/* Text Section */}
      </div>
    </div>
  );
}

export default BlackCard;