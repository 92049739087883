// DatabaseCard.js

import React from 'react';
import './DatabaseCard.css'; // Ensure your styles are correctly linked
import StatsCardComponent from '../StatsCard/StatsCard';
import ListIcon from '@mui/icons-material/List';  // Example icon for list items
import CategoryIcon from '@mui/icons-material/Category';  // Example icon for categories
import UpdateIcon from '@mui/icons-material/Update';  // Example icon for updates

const DatabaseCard = () => {
    return (
        <div className="p-5">
            <div className="flex">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img src="database.jpeg" alt="Relevant Imagery" className="img-fluid" style={{ maxWidth: '100%' }} />
                </div>
                <div className="">
                    <h2 className="">Access up-to-date radiation test data in an expert-curated, searchable database</h2>
                    <ul className="">
                        <li><ListIcon /> Curated compilation of test results from public and private sources</li>
                        <li><CategoryIcon /> Categorized data with key results extraction</li>
                        <li><UpdateIcon /> Continuously refreshed, with provision for user-requested updates</li>
                    </ul>
                    <StatsCardComponent />
                </div>
            </div>
        </div>
    );
}

export default DatabaseCard;
