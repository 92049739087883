import React, { useState } from "react";
import { TextField, Autocomplete, Button, Box } from "@mui/material";
import useFetchUsers from "../../hooks/useFetchUsers";
import useFetchDataSources from "../../hooks/useFetchDataSources";
import useFetchTestResultsFromDataSource from "../../hooks/useFetchTestResultsFromDataSource";
import useAddUserToTestResult from "../../hooks/useAddUserToTestResult";
import { FormContainer, StatusMessage } from "./AdminCss";

const AddUserToTestResultForm = () => {
  const { addUserToTestResult, statusMessage } = useAddUserToTestResult();

  // Setup autocomplete options for datasources
  const datasources = useFetchDataSources();
  const [selectedDataSource, setSelectedDataSource] = useState(null);

  // Setup autocomplete options for users
  const users = useFetchUsers();
  const [selectedUser, setSelectedUser] = useState(null);

  // State for test results
  const [selectedTestResult, setSelectedTestResult] = useState(null);

  // Fetch test results based on the selected data source
  const testResults = useFetchTestResultsFromDataSource(
    selectedDataSource ? selectedDataSource.title : null
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      selectedUser: selectedUser,
      selectedDataSource: selectedDataSource,
      selectedTestResult: selectedTestResult,
    };

    addUserToTestResult(payload);
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "30px",
  };

  const inputSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(145, 158, 171, 0.8)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
      borderColor: "rgba(145, 158, 171, 0.2)",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(145 158 171)",
      "&.Mui-focused": {
        color: "black",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
      opacity: 1,
    },
    "& .MuiInputBase-input:focus::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
    },
  };

  const buttonSx = {
    mt: 2,
    mb: 1,
    borderRadius: "12px",
    backgroundColor: "#F26E21",
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    px: 2,
    py: 2,
    minWidth: 200,
    height: "48px",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
  };

  return (
    <FormContainer>
      <Box
        component="form"
        onSubmit={handleSubmit}
        autoComplete="off"
        style={{ width: "100%" }}
      >
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Users"
              autoComplete="off"
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedUser}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          fullWidth
          sx={inputSx}
        />

        <Autocomplete
          options={datasources}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Sources"
              autoComplete="off"
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedDataSource}
          onChange={(event, newValue) => setSelectedDataSource(newValue)}
          fullWidth
          sx={inputSx}
        />

        <Autocomplete
          options={testResults || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Test Results"
              autoComplete="off"
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedTestResult}
          onChange={(event, newValue) => setSelectedTestResult(newValue)}
          fullWidth
          sx={inputSx}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            width: "100%",
          }}
        >
          <Button variant="contained" type="submit" sx={buttonSx}>
            Submit
          </Button>
        </Box>

        {statusMessage && (
          <StatusMessage
            style={{
              color: statusMessage.type === "success" ? "green" : "red",
            }}
          >
            {statusMessage.text}
          </StatusMessage>
        )}
      </Box>
    </FormContainer>
  );
};

export default AddUserToTestResultForm;
