// AdvancedFeaturesCard.js

import React from 'react';
import CalculatorIcon from '@mui/icons-material/Calculate'; // Icon for calculations
import FilterListIcon from '@mui/icons-material/FilterList'; // Icon for database filters
import ThumbUpIcon from '@mui/icons-material/ThumbUp'; // Icon for recommendations
import './AdvancedFeaturesCard.css';
import AdvancedFeaturesSubCardComponent from '../AdvancedFeaturesSubCard/AdvancedFeaturesSubCard';

const AdvancedFeaturesCard = () => {
  return (
    <div className="container-orange p-5" id="advanced-features-card">
      <div className="row">
        {/* Text Section */}
        <div className="col-md-6 text-start">
          <h2 className="bulletPoints-letters-white fw-bold mb-4" id="advanced-features-title">Nucleon Mission Environment Features</h2>
          <ul className="features-list fs-5 fw-bold" id="advanced-features-list">
            <li id="advanced-features-item-1">
              <CalculatorIcon className="feature-icon" />
              Access user-friendly tools to calculate mission radiation environments
            </li>
            <li id="advanced-features-item-2">
              <FilterListIcon className="feature-icon" />
              Seamlessly integrate calculations with database filters for electronic part selection
            </li>
            <li id="advanced-features-item-3">
              <ThumbUpIcon className="feature-icon" />
              Receive expert-reviewed recommendations for optimal part choices
            </li>
          </ul>
          <AdvancedFeaturesSubCardComponent />
        </div>
        {/* Image Section */}
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src="mission_env_image_big.jpg" alt="Relevant Imagery" className="img-fluid" id="advanced-features-image" style={{maxWidth: '100%'}} />
        </div>
      </div>
    </div>
  );
}

export default AdvancedFeaturesCard;