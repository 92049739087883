import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const RestrictedFeatureDialog = ({ open }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Feature Restricted</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    The analysis feature is not available for trial users. Please visit our <a href="https://nucleonspace.com/pricing">pricing page</a> to upgrade.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGoBack} color="primary">
                    Go Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RestrictedFeatureDialog;