import React from 'react';
import './RadiationTestingSubCard.css'; // Make sure to create this CSS file
import {Button} from 'react-bootstrap';

const RadiationTestingSubCardComponent = () => {
  return (
    <div className="container-orange-b card.orange text-center text-white">
      <div className="card-body">
      <Button href="/pricing">SUBSCRIBE TODAY</Button>
      </div>
    </div>
  );
};

export default RadiationTestingSubCardComponent;