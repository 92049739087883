import React from "react";
import PartsPerYear from "./PartsPerYear";
import TIDCumulativePlot from "./TIDCumulative";
import HICumulativePlot from "./HICumulative";
import MFGLETBoxPlot from "./MFGLETBoxPlot";
import MFGTIDBoxPlot from "./MFGTIDBoxPlot";
import CategoryTIDBoxPlot from "./CategoryTIDBoxPlot";
import CategoryLETBoxPlot from "./CategoryLETBoxPlot";
import SpaceRatedLETBoxPlot from "./SpaceRatedLETBoxPlot";
import useTrialUserCheck from "../../TrialUserCheck/useTrialUserCheck";
import RestrictedFeatureDialog from "../../TrialUserCheck/RestrictedFeatureDialog";
import PageHeader from "../PageHeader/page-header";
import { Box } from "@mui/material";

const Analysis = () => {
  const { isTrialUser, isLoading } = useTrialUserCheck();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isTrialUser) {
    return <RestrictedFeatureDialog open={true} />;
  }

  return (
    <Box>
      <PageHeader
        title="Database Analysis"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Analysis", href: "/dashboard/analysis" },
        ]}
        showButton={false}
      />
      <HICumulativePlot />
      <TIDCumulativePlot />
      <MFGLETBoxPlot />
      <MFGTIDBoxPlot />
      <CategoryTIDBoxPlot />
      <CategoryLETBoxPlot />
      <SpaceRatedLETBoxPlot />
      <PartsPerYear />
    </Box>
  );
};

export default Analysis;
