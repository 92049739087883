import { useState } from 'react';

const useEditPartInformation = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const editPartInformation = async (payload, onClose) => {
        setStatusMessage(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/edit-partinformation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'Part Information updated successfully!' });
                onClose();
                window.location.reload(); // Force a refresh of the page
            } else {
                throw new Error(data.message || 'Failed to update test result.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { editPartInformation, statusMessage };
};

export default useEditPartInformation;
