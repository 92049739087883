import React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';

const SubmissionRow = ({ submission, onStatusChange }) => {
    return (
        <TableRow>
            <TableCell>{submission.id}</TableCell>
            <TableCell>{submission.approval_status}</TableCell>
            <TableCell>{submission.file_name}</TableCell>
            <TableCell>{submission.manufacturer}</TableCell>
            <TableCell>{submission.test_type}</TableCell>
            <TableCell>{submission.description}</TableCell>
            <TableCell>{submission.part}</TableCell>
            <TableCell>{submission.price}</TableCell>
            <TableCell>{new Date(submission.created_at).toLocaleString()}</TableCell>
            <TableCell>{new Date(submission.updated_at).toLocaleString()}</TableCell>
            <TableCell>
                <Button 
                    variant="contained" 
                    sx={{ backgroundColor: 'green', color: 'white', '&:hover': { backgroundColor: 'darkgreen' } }} 
                    onClick={() => onStatusChange(submission.id, 'approved')}
                >
                    Approve
                </Button>
                <Button 
                    variant="contained" 
                    sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } }} 
                    onClick={() => onStatusChange(submission.id, 'rejected')}
                >
                    Reject
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default SubmissionRow;