import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const HowToSell = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "white", flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          py: 4,
          backgroundColor: "white",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          borderRadius: "16px",
          padding: "16px 16px 16px 24px",
          // height: `calc(100vh - ${215}px)`,
        
        }}
      >
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              color: "#1C252E",
            }}
          >
            Selling Data
          </Typography>
          <Typography
            sx={{ my: 2, fontFamily: "'Poppins', sans-serif", color: "#637381" }}
          >
            Nucleon Marketplace is the best place to sell your data - and we’ve
            made the process easy.
          </Typography>
          <Box sx={{ my: 5 }}>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: "'Poppins', sans-serif",
                color: "#1C252E",
                fontWeight: 500,
              }}
            >
              Submit Your Data
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "#637381",
                fontSize: "0.875rem",
              }}
            >
              It’s free to submit your data. We simply ask you to fill out the
              sellers form for the data - including a brief description of data, a
              pdf of the data, and your initial desired price. All your data is
              treated confidential and will not be given to anyone who has not
              first bought it.
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                color: "#1C252E",
              }}
            >
              We Will Analyze and Grade the Data
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "#637381",
                fontSize: "0.875rem",
              }}
            >
              We will review the data, provide a grade for it, and include our
              suggested price.
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                color: "#1C252E",
              }}
            >
              Finalize Your Data Sell
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "#637381",
                fontSize: "0.875rem",
              }}
            >
              Once we have graded your data and given our suggested price, you
              will just need to finalize the price you want to list at and confirm
              the listing of your data on the marketplace.
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                color: "#1C252E",
              }}
            >
              Finalizing the sell
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "#637381",
                fontSize: "0.875rem",
              }}
            >
              We will list your data on the marketplace. We may reach out with any
              questions from potential buyers to help facilitate the sell. Once a
              buyer purchases your data we will pay you once we have received
              payment from the buyer.
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "#637381",
                fontSize: "0.875rem",
              }}
            >
              Please contact us before uploading any files that have export
              compliance restrictions.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            bottom: 20,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <InfoIcon
            sx={{
              fontSize: "18px",
              fontFamily: "'Poppins', sans-serif",
              color: "#1C252E",
            }}
          />
          &nbsp;
          <Typography sx={{ color: "#1C252E" }}>
            For any questions email marketplace@cyclotechnolgies.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HowToSell;
