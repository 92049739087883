import { useState, useEffect } from 'react';

const useFetchCategoriesByGroup = (groupName) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategoriesByGroup = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/categories_by_group`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ groupName: groupName })
                });
                const data = await response.json();
                if (response.ok) {
                    setCategories(data);
                } else {
                    throw new Error('Failed to fetch category groups');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };
        if (groupName) {
            fetchCategoriesByGroup();
        }
    }, [groupName]);

    return categories;
};

export default useFetchCategoriesByGroup;