import React from 'react';
import './Copyright.css'; // Make sure to create this CSS file

export default function Copyright(props) {
  return (
    <div className="copyright-text copy-right-text-white bg-black" {...props}>
      Copyright © 
      <a href="https://cyclotechnologies.com/" className="copy-right-text-white">
        Cyclo Technologies, Inc
      </a>
      {` ${new Date().getFullYear()}.`}
      <a href={`${process.env.PUBLIC_URL}/privacy_policy.pdf`} className="pdf-link">Privacy Policy</a>
      <a href={`${process.env.PUBLIC_URL}/Cyclo Technologies Inc - Terms of Use for https___www.cyclotechnologies.com.pdf`} className="pdf-link">Terms of Service</a>
    </div>
  );
}