import { useState, useEffect } from 'react';

const useFetchCategoryDetails = (categoryName) => {
    const [details, setDetails] = useState([]);

    useEffect(() => {
        const fetchCategoryDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/category_details`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ categoryName: categoryName })
                });
                const data = await response.json();
                if (response.ok) {
                    setDetails(data);
                } else {
                    throw new Error('Failed to fetch category details.');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };
        if (categoryName) {
            fetchCategoryDetails();
        }
    }, [categoryName]);

    return details;
};

export default useFetchCategoryDetails;