import React from 'react';
import './AdvancedFeaturesSubCard.css';

const AdvancedFeaturesSubCardComponent = () => {
  return (
    <div className="container-black-af card text-center text-white">
      <div className="card-body">
        <p className="card-text fs-5 mb-3">Choose the parts that are right for your mission</p>
    </div>
    </div>
  );
};

export default AdvancedFeaturesSubCardComponent;