import React, { useState } from 'react';
import SubmissionsTable from './SubmissionsTable';

const MarketplaceAdmin = () => {
    const [activeForm, setActiveForm] = useState('SubmissionsTable');

    const handleFormChange = (event) => {
        setActiveForm(event.target.value);
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1 style={{ marginBottom: '20px' }}>Marketplace Admin Panel</h1>
            <select 
                style={{ marginBottom: '20px' }} 
                onChange={handleFormChange} 
                value={activeForm}
            >
                <option value="SubmissionsTable">Submissions Table</option>
                {/* Add more options as you add more forms */}
            </select>
            <div>
                {activeForm === 'SubmissionsTable' && <SubmissionsTable />}
                {/* Add more conditions as you add more forms */}
            </div>
        </div>
    );
};

export default MarketplaceAdmin;
