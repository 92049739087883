import React from "react";
import "./LogoutButton.css"; // Ensure the path to your CSS file is correct
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { IconButton } from "@mui/material";
// import { Button } from "@mui/material";

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      // Call Flask logout to clear application-specific cookies or sessions
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        { method: "POST", credentials: "include" }
      );
      if (response.ok) {
        // Construct the Cognito logout URL
        const logoutUri = encodeURIComponent(
          process.env.REACT_APP_LOGOUT_REDIRECT_URI
        );
        const logoutUrl = `https://nucleon.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=8kbk40oimit7o9lmfini3f9sf&logout_uri=${logoutUri}`;

        // Redirect to Cognito logout URL
        window.location.assign(logoutUrl);
      } else {
        throw new Error("Failed to clear server-side session");
      }
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Logout failed. Please try again.");
    }
  };

  return (
    <IconButton onClick={handleLogout}>
      <ExitToAppRoundedIcon sx={{ width: 30, height: 30 }} />
    </IconButton>
  );
};

export default LogoutButton;
