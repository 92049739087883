import React from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box, Typography } from "@mui/material";
import emphasizeIcon from "../../../assets/empty.svg";

const NucleonDataGrid = ({
  rows,
  columns,
  total,
  getRowId,
  showToolbar = false,
  paginationModel = { pageSize: 5, page: 0 },
  setPaginationModel = () => {},
  ...props
}) => {
  console.log({ rows, columns, total, showToolbar, paginationModel });
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            alignItems: "center",
            padding: 1,
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          <GridToolbarDensitySelector
            slotProps={{
              button: {
                sx: {
                  whiteSpace: "wrap",
                  width: "fit-content",
                  color: "#F26E21",
                  "&:hover": {
                    backgroundColor: "rgba(242, 110, 33, 0.1)",
                    "& .MuiSvgIcon-root": { margin: 0 },
                  },
                  "& .MuiButton-startIcon": {
                    margin: 0,
                  },
                },
              },
            }}
          />
          <GridToolbarExport
            slotProps={{
              button: {
                sx: {
                  fontFamily: "'Poppins', sans-serif",
                  whiteSpace: "wrap",
                  width: "fit-content",
                  color: "#F26E21",
                  "&:hover": {
                    backgroundColor: "rgba(242, 110, 33, 0.1)",
                    "& .MuiSvgIcon-root": { margin: 0 },
                  },
                  "& .MuiButton-startIcon": {
                    margin: 0,
                  },
                },
              },
            }}
          />
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <DataGridPro
      {...props}
      rows={rows}
      columns={columns.map((col) => ({
        ...col,
        renderCell: (params) => {
          if (col.renderCell) {
            return (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
                pl={2}
              >
                {col.renderCell(params)}
              </Box>
            );
          }

          return (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              height="100%"
              pl={2}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "normal",
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "pre-wrap",
                  width: "100%",
                  height: "100%",
                  overflowWrap: "break-word",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                {params.value ?? "N/A"}
              </Typography>
            </Box>
          );
        },
      }))}
      rowCount={total}
      rowL
      getRowId={getRowId}
      localeText={{
        toolbarColumns: "",
        toolbarFilters: "",
        toolbarDensity: "",
        toolbarExport: "",
      }}
      getRowHeight={() => "auto"}
      checkboxSelection={false}
      loading={false}
      disableSelectionOnClick
      disableColumnResize
      disableColumnSelector
      disableRowSelectionOnClick
      pagination={rows.length > 0}
      page={paginationModel.page}
      pageSize={props.pageSize || paginationModel.pageSize}
      paginationMode={props.paginationMode || "server"}
      onPageSizeChange={(newPageSize) => {
        setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize }));
      }}
      onPageChange={(newPage) => {
        setPaginationModel((prev) => ({ ...prev, page: newPage }));
      }}
      // onFilterModelChange={(newModel) => setFilterModel(newModel)}
      // filterModel={filterModel}
      onPaginationModelChange={(newModel) => {
        setPaginationModel(newModel);
      }}
      slots={{
        toolbar: () => (showToolbar ? <CustomToolbar /> : null),
        noRowsOverlay: () => {
          return (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={emphasizeIcon}
                style={{ width: "120px", height: "auto" }}
                alt=""
              />
            </Box>
          );
        },
      }}
      sx={{
        flexGrow: 1,
        fontFamily: "'Poppins', sans-serif !important",
        color: "#1C252E",
        boxShadow:
          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        border: "none",
        borderRadius: "16px",
        paddingBottom: "8px",
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none",
          },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "semi-bold",
          fontSize: "16px",
          color: "#637381",
        },
        "& .MuiDataGrid-columnHeader": {
          paddingLeft: "24px",
          color: "rgba(0, 0, 0, 0.4)",
          borderBottom: "1px solid rgba(145, 158, 171, 0.2)",
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "'Poppins', sans-serif",
          color: "#1C252E",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "visible",
          p: 1,
        },
        "& .MuiDataGrid-cell--editable": {
          fontFamily: "'Poppins', sans-serif",
        },
        "& .MuiDataGrid-footerContainer": {
          justifyContent: "flex-end",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          color: "rgba(0, 0, 0, 0.4)",
        },
        "& .MuiTablePagination-toolbar": {
          flexWrap: "wrap",
          fontFamily: "'Poppins', sans-serif",
        },
        "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
          {
            fontFamily: "'Poppins', sans-serif",
            marginBottom: 0,
            paddingBottom: 0,
          },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "transparent",
        },
        "& .MuiDataGrid-filler": {
          backgroundColor: "#FFFFFF",
        },
        "& .MuiDataGrid-scrollbarFiller": {
          backgroundColor: "#FFFFFF",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "#F4F6F8",
        },
        "& .MuiDataGrid-row:not(:first-of-type) .MuiDataGrid-cell": {
          borderTop: "1px dashed rgba(145, 158, 171, 0.2) !important",
        },
        "& .MuiDataGrid-selectedRowCount": {
          display: "none",
        },
        "& .MuiInputLabel-root": {
          color: "#637381",
          fontFamily: "'Poppins', sans-serif",
        },
      }}
    />
  );
};

export default NucleonDataGrid;
