import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Tabs,
  Tab,
  Button,
  Modal,
  Typography,
  CircularProgress,
  Link,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageHeader from "../PageHeader/page-header";
import NucleonDataGrid from "../NucleonDataGrid/nucleon-data-grid";
import NucleonButton from "../NucleonButton/nucleon-button";
// import { GridToolbar } from "@mui/x-data-grid";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";

function MarketplaceDataTable() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [purchasedRows, setPurchasedRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchPartsForSale();
    fetchPurchasedParts();
  }, [navigate, activeTab]);

  const fetchPartsForSale = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/getPartsForSale`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const partsColumns = [
        {
          field: "details",
          headerName: "Details",
          width: 150,
          renderCell: (params) => (
            <NucleonButton
              isActive={true}
              handleButtonClick={() => handleOpenModal(params.row)}
            >
              Details
            </NucleonButton>
          ),
        },
        { field: "id", headerName: "ID", width: 90 },
        { field: "part", headerName: "Part", width: 150 },
        { field: "manufacturer", headerName: "Manufacturer", width: 150 },
        {
          field: "description",
          headerName: "Description",
          width: 200,
          renderCell: (params) => (
            <Tooltip title={params.value}>
              <Typography sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", cursor: "default", fontFamily: "Poppins, sans-serif" }}>
                {params.value}
              </Typography>
            </Tooltip>
          )
        },
        { field: "price", headerName: "Price", width: 110 },
        { field: "test_type", headerName: "Test Type", width: 150 },
      ];

      setAllRows(data);
      if (activeTab === 0) {
        setRows(data);
        setColumns(partsColumns);
      }
    } catch (error) {
      console.error("Error fetching parts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPurchasedParts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/getPurchasedParts`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const purchasedColumns = [
        {
          field: "details",
          headerName: "Details",
          width: 150,
          renderCell: (params) => (
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff8600", color: "#FFFFFF" }}
              onClick={() => handleOpenModal(params.row)}
            >
              Details
            </Button>
          ),
        },
        { field: "id", headerName: "ID", width: 90 },
        { field: "part", headerName: "Part", width: 150 },
        { field: "manufacturer", headerName: "Manufacturer", width: 150 },
        { field: "description", headerName: "Description", width: 200 },
        { field: "price", headerName: "Price", width: 110 },
        { field: "test_type", headerName: "Test Type", width: 150 },
        { field: "purchase_price", headerName: "Purchase Price", width: 150 },
      ];

      setPurchasedRows(data);
      if (activeTab === 1) {
        setRows(data);
        setColumns(purchasedColumns);
      }
    } catch (error) {
      console.error("Error fetching purchased parts:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        setRows(allRows);
        setColumns([
          {
            field: "details",
            headerName: "Details",
            width: 150,
            renderCell: (params) => (
              <Button
                variant="contained"
                style={{ backgroundColor: "#ff8600", color: "#FFFFFF" }}
                onClick={() => handleOpenModal(params.row)}
              >
                Details
              </Button>
            ),
          },
          { field: "id", headerName: "ID", width: 90 },
          { field: "part", headerName: "Part", width: 150 },
          { field: "manufacturer", headerName: "Manufacturer", width: 150 },
          { field: "description", headerName: "Description", width: 200 },
          { field: "price", headerName: "Price", width: 110 },
          { field: "cachelink", headerName: "Cache Link", width: 200 },
          { field: "test_type", headerName: "Test Type", width: 150 },
        ]);
        break;
      case 1:
        setRows(purchasedRows);
        setColumns([
          {
            field: "details",
            headerName: "Details",
            width: 150,
            renderCell: (params) => (
              <Button
                variant="contained"
                style={{ backgroundColor: "#ff8600", color: "#FFFFFF" }}
                onClick={() => handleOpenModal(params.row)}
              >
                Details
              </Button>
            ),
          },
          { field: "id", headerName: "ID", width: 90 },
          { field: "part", headerName: "Part", width: 150 },
          { field: "manufacturer", headerName: "Manufacturer", width: 150 },
          { field: "description", headerName: "Description", width: 200 },
          { field: "price", headerName: "Price", width: 110 },
          { field: "cachelink", headerName: "Cache Link", width: 200 },
          { field: "test_type", headerName: "Test Type", width: 150 },
          { field: "purchase_price", headerName: "Purchase Price", width: 150 },
        ]);
        break;
    }
  };

  const handleOpenModal = (part) => {
    setSelectedPart(part);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPart(null);
  };

  const handleApprove = async (partId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/approve_part/${partId}`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await fetchPartsForSale(); // Refresh parts for sale after approval
    } catch (error) {
      console.error("Error approving part:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: `calc(100vh - ${90}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader
        title="Marketplace"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Marketplace", href: "/dashboard/marketplace" },
        ]}
        showButton={false}
      />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Buy Parts" />
        <Tab label="Purchased Parts" />
      </Tabs>
      {loading ? (
        <CircularProgress />
      ) : (
        <NucleonDataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          showToolbar
          paginationMode="client"
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          getRowId={(row) => row.id}
          componentsProps={{
            row: {
              onClick: (params) => {
                if (activeTab === 2) {
                  handleApprove(params.id);
                }
              },
            },
          }}
        />
      )}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="part-details-modal"
        aria-describedby="part-details-modal-description"
      >
        {/* <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        > */}
        <NucleonWrapper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 5,
            minWidth: "800px",
          }}
        >
          {selectedPart ? (
            <>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  fontFamily: "'Poppins', sans-serif",
                }}
                id="part-details-modal"
                variant="h6"
                component="h2"
              >
                {selectedPart.part}
              </Typography>
              <Typography
                id="part-details-modal-description"
                sx={{
                  mt: 2,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                Manufacturer:{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {" "}
                  {selectedPart.manufacturer}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                Description:{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {" "}
                  {selectedPart.description}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                Price:{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {" "}
                  ${selectedPart.price}{" "}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                Test Type:{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {" "}
                  {selectedPart.test_type}{" "}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  mb: 2,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                How To Purchase Part:{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                    mb: 2,
                  }}
                >
                  {" "}
                  To purchase this part,{" "}
                </Typography>
                please email:&nbsp;
                <Link href="mailto:marketplace@cyclotechnologies.com">
                  marketplace@cyclotechnologies.com
                </Link>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#637381",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {" "}
                  &nbsp;to request an invoice.{" "}
                </Typography>
              </Typography>
              {/* Add more fields as necessary */}
            </>
          ) : (
            <CircularProgress />
          )}
          {/* </Box> */}
        </NucleonWrapper>
      </Modal>
    </Box>
  );
}

export default MarketplaceDataTable;
