// WithStripe.js
import React, { useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51KWRXKAyIFOvw3rMRAdsMqQ0mRYp5xbzctiQI0wjHFa5nZUYhb2O5OkGpbdqFoPPIi9831R5lpbiXHmxjQaipRK300qk6QqZyk');

const WithStripe = ({ children }) => {
  const stripeTableRef = useRef(null);

  useEffect(() => {
    // Function to load the Stripe pricing table script
    const loadStripePricingTable = () => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
      
      script.onload = () => {
        // Script has loaded, now we can initialize the pricing table
        if (stripeTableRef.current) {
          stripeTableRef.current.innerHTML = `
            <stripe-pricing-table pricing-table-id="prctbl_1Lpck6AyIFOvw3rMSLHgDzWX" 
            publishable-key="pk_live_51KWRXKAyIFOvw3rMRAdsMqQ0mRYp5xbzctiQI0wjHFa5nZUYhb2O5OkGpbdqFoPPIi9831R5lpbiXHmxjQaipRK300qk6QqZyk">
            </stripe-pricing-table>
          `;
        }
      };
    };
    
    // Load the Stripe pricing table script
    loadStripePricingTable();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {/* This div will serve as a mounting point for the Stripe pricing table */}
      <div ref={stripeTableRef} id="stripe-pricing-table"></div>
      {children}
    </Elements>
  );
};

export default WithStripe;