import React, { useState } from 'react';
import { Button,Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const UploadBOMForm = ({ bomId }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Set the file to the first file selected
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        await fetch(`${process.env.REACT_APP_API_URL}/dashboard/upload_bom/${bomId}`, {
            method: 'POST',
            body: formData,
            credentials: 'include', // Include credentials if your API requires authentication
        });

        window.location.reload(); // Reload the page to update the UI
    };




    const StyledLabel = styled('label')(( ) => ({
        mt: 2,
        mb: 3,
        borderRadius: "12px",
        backgroundColor: "#F26E21",
        color: "#fff",
        textAlign: "center",
        fontSize: "14px",
        px: 3,
        py: 1,
        cursor: "pointer",
        fontFamily: "'Poppins', sans-serif",
        textTransform: "capitalize",
        width:"120px",
        height:"40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "#F26E21",
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: "#F26E21",
          boxShadow: "none",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "#F26E21",
          boxShadow: "none",
        },
      }));






    return (
        <Box sx={{display:"flex", justifyContent: "center",
            alignItems: "center", ga:4 }} >
            <StyledLabel>
    Select File
    <input
      type="file"
      onChange={handleFileChange}
      accept=".xlsx"
      style={{ display: 'none' }}
    />
  </StyledLabel>
            <Button onClick={handleUpload} variant="contained" color="primary" disabled={!file}  sx={{
                ml:4,
                    mt: 2,
                    mb: 2,
                    borderRadius: "12px",
                    backgroundColor: "#F26E21",
                    color: "#fff",
                    textAlign: "center",
                    fontSize: "14px",
                    px: 3,
                    py: 1,
                    cursor: "pointer",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                        backgroundColor: "#F26E21",
                        boxShadow: "none",
                    },
                    "&:active": {
                        backgroundColor: "#F26E21",
                        boxShadow: "none",
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#F26E21",
                        boxShadow: "none",
                    },
                }}  >
                Upload BOM
            </Button>
        </Box>
    );
};

export default UploadBOMForm;
