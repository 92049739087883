import React, { useState, useEffect } from 'react';
import SubmissionRow from './SubmissionRow';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const SubmissionsTable = () => {
    const [submissions, setSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSubmissions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/getUserSubmissions`, {
                    method: 'POST',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSubmissions(data);
            } catch (error) {
                console.error('Error fetching submissions:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubmissions();
    }, []);

    const handleStatusChange = async (id, status) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/approve_part/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status }),
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                const updatedSubmission = data.submission;
                setSubmissions(prevSubmissions =>
                    prevSubmissions.map(submission =>
                        submission.id === id ? updatedSubmission : submission
                    )
                );
                alert(`Submission ${status} successfully.`);
            } else {
                alert('Failed to update submission status.');
            }
        } catch (error) {
            console.error('Error updating submission status:', error);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <TableContainer component={Paper} sx={{ padding: '30px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Submission Status</TableCell>
                        <TableCell>File Name</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>Test Type</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Part</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {submissions.map(submission => (
                        <SubmissionRow
                            key={submission.id}
                            submission={submission}
                            onStatusChange={handleStatusChange}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubmissionsTable;