import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { styled } from "@mui/system";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {
  subscriptionDataYearly,
  subscriptionDataMonthly,
} from "../../constants/pricing";
import SectionHeader from "../SectionHeader/section-header";
import AnimateButton from "../Button/AnimateButton";

const PricingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(3),
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(10),
  padding: "0px 10px",
  flexWrap: "wrap",
  borderRadius: 3,
  border: "none",
}));

const PlanCard = styled(Card)(({ theme }) => ({
  maxWidth: 380,
  height: "auto",
  position: "relative",
  marginBottom: "50px",
  borderRadius: "20px",
  boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;",
  transition: "transform 0.3s ease-in-out",
  border: "none",
  "&:hover": {
    transform: "translateY(-40px)",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;",
  },
  [theme.breakpoints.down("sm")]: {
    "&:hover": {
      transform: "none",
      boxShadow: "0 25px 10px rgba(0, 0, 0, 0.3)",
    },
    maxWidth: 350,
  },
}));

const PriceBox = styled(Box)(({ bgColor }) => ({
  background: bgColor,
  padding: 30,

  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const PriceTypography = styled(Typography)(({ color }) => ({
  position: "relative",
  fontSize: 50,
  textAlign: "center",
  paddingBottom: 40,
  color,
  fontWeight: 600,
  alignItems: "baseline",
  fontFamily: "'Poppins', sans-serif",
  lineHeight: "55px",
  border: "none",
}));

const PricePerMonth = styled(Typography)(({ color }) => ({
  fontSize: 16,
  fontWeight: 500,
  color,
  fontFamily: "'Poppins', sans-serif",
}));

const Description = styled(Typography)(({ color }) => ({
  fontSize: 14,
  fontWeight: 400,
  color,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",

  lineHeight: 1.75,
  fontFamily: "'Poppins', sans-serif",
}));

const PlanTitle = styled(Typography)(({ color }) => ({
  marginBottom: 16,
  textAlign: "center",
  color,
  fontSize: 28,
  fontWeight: 500,
  fontFamily: "'Poppins', sans-serif",
}));

const FeatureList = styled(Box)(({ theme }) => ({
  marginTop: "0px",
  paddingBottom: "32px",
  paddingLeft: "3rem",
  paddingRight: "3rem",
  display: "flex",
  flexDirection: "column",
  height: 250,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
}));

const FeatureItem = styled(Typography)(({ color }) => ({
  marginBottom: 20,
  textAlign: "left",
  fontSize: 16,
  fontWeight: 400,
  color,
  display: "flex",
  gap: 10,
  fontFamily: "'Poppins', sans-serif",
  borderBottom: "1px solid #cdc8c8",
  lineHeight: "35px",
}));

// const ButtonBox = styled(Box)({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   position: "absolute",
//   bottom: "30px",

// });

const StyledButtonGroup = styled(ButtonGroup)({
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
  borderRadius: "12px",
  backgroundColor: "#fff",
  padding: "5px",
  width: 300,
  alignItems: "center",
  border: "2px solid #dbdbdb",
});

const StyledButton = styled(Button)(({ active, direction }) => ({
  flex: 1,
  fontFamily: "'Poppins', sans-serif",
  background: active
    ? `linear-gradient(to ${direction}, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))`
    : "transparent",
  color: active ? "#F26E21" : "gray",
  border: `1px solid #fff`,
  padding: "10px 40px",
  borderRadius: "12px",
  transition: "background 0.3s ease-in-out", // add transition for smooth gradient animation
  "&:hover": {
    background: active
      ? `linear-gradient(to ${direction}, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))`
      : "transparent",
    color: active ? "#F26E21" : "#F26E21",
    border: `1px solid #fff`,
  },
  fontWeight: "600",
}));

const PricingCardTable = () => {
  const [activePlan, setActivePlan] = useState("Monthly");
  const plans =
    activePlan === "Monthly" ? subscriptionDataMonthly : subscriptionDataYearly;
  return (
    <Box sx={{ padding: 0 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        <SectionHeader title="PRICING" subtitle="Plans & Pricing" />

        <Box
          sx={{
            display: "flex",
            mb: 4,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 0,
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          <StyledButtonGroup>
            <StyledButton
              onClick={() => setActivePlan("Monthly")}
              active={activePlan === "Monthly"}
              direction="right"
            >
              Monthly
            </StyledButton>
            <StyledButton
              onClick={() => setActivePlan("Yearly")}
              active={activePlan === "Yearly"}
              direction="left"
            >
              Yearly
            </StyledButton>
          </StyledButtonGroup>
        </Box>
      </Box>

      <PricingBox>
        {plans.map((plan) => (
          <PlanCard
            key={plan.title}
            sx={{ transform: { lg: `${plan.tag && "translateY(-40px)"}` } }}
            variant="outlined"
          >
            <PriceBox bgColor={plan.bgColor}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {plan.tag && (
                  <Typography
                    sx={{
                      background:
                        "linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(0, 122, 255, 0))",
                      fontFamily: "'Poppins', sans-serif",
                      px: "14px",
                      py: "3px",
                      textAlign: "center",
                      borderRadius: "25px",
                      fontSize: "14px",
                      color: "#F26E21",
                      fontWeight: "400",
                      mb: 2,
                    }}
                  >
                    {plan.tag}
                  </Typography>
                )}

                <PlanTitle color={plan.SubscriptionText}>
                  {plan.title}
                </PlanTitle>
              </Box>

              <Description color="#313E5B">{plan.description}</Description>
            </PriceBox>

            <Box
              sx={{
                backgroundColor: plan.cardBottomColor,
                minHeight: { xs: 680, sm: 550 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <PriceTypography color="#F26E21">
                  <sup
                    style={{
                      fontSize: "25px",
                      paddingBottom: "10px",
                      top: "-1em",
                    }}
                    color="#F26E21"
                  >
                    $
                  </sup>
                  {plan.price}
                  <PricePerMonth color="#3B3664">
                    {activePlan === "Yearly" ? "YEARLY" : "MONTHLY"}
                  </PricePerMonth>
                </PriceTypography>
              </Box>
              <FeatureList>
                {plan.features.map((feature, index) => (
                  <FeatureItem key={index} color="#313E5B">
                    <IoCheckmarkCircleSharp
                      color="#F26E21"
                      size={20}
                      style={{ marginTop: "5px", minWidth: 20 }}
                    />
                    {feature}
                  </FeatureItem>
                ))}
              </FeatureList>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // left:{ xs: "65px", sm: "100px", lg: "100px" }
                }}
              >
                <AnimateButton variant="contained" href={plan.link}>
                  Subscribe
                </AnimateButton>
              </Box>
            </Box>
          </PlanCard>
        ))}
      </PricingBox>
    </Box>
  );
};

export default function OutlinedCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <PricingCardTable />
    </Box>
  );
}
