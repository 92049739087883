import React from "react";
import {
  Box,
  Typography,
  CardContent,
  CardMedia,
  Link,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionHeader from "../SectionHeader/section-header";
import { SiMaildotru } from "react-icons/si";

import { FaLinkedinIn } from "react-icons/fa";
import { TEAM_MEMBERS } from "../../constants/about-us";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1000, min: 768 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const AboutUs = () => {
  return (
    <Box sx={{ backgroundColor: "#F9F9FA", pt: 0.5, borderRadius: 4 }}>
      <SectionHeader title="Team" subtitle="The Team Behind Nucleon." />
      <Box
        sx={{
          mt: 5,
          backgroundColor: "#F9F9FA",
          paddingBlock: 4,
          borderRadius: 2,
        }}
      >
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          partialVisible={true}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          keyBoardControl={true}
          autoPlay={true}
        >
          {TEAM_MEMBERS.map((member, index) => {
            return (
              <Box
                sx={{
                  backgroundColor: "#ffff",
                  borderRadius: 4,
                  marginLeft: { lg: "40px", md: "15px", sm: "10px" },
                  marginRight: { lg: "40px", md: "15px", sm: "10px" },
                  position: "relative",

                  "&:hover .hoverButton": {
                    transform: "translateX(-50%) translateY(-200%)",
                  },
                }}
                key={index}
              >
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={member.image}
                    alt={member.name}
                    draggable="false"
                    sx={{
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                      objectFit: "cover",
                      zIndex: 10,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    zIndex: 30,
                    position: "relative",
                    borderRadius: "12px",
                  }}
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                      padding: 2,
                      paddingBottom: "35px",
                      paddingTop: "35px",
                      zIndex: 30,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#313e5b",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {member.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        color: "#525f81",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {member.position}
                    </Typography>
                  </CardContent>

                  <Box
                    className="hoverButton"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "50%",
                      transform: "translateX(-50%) translateY(0)",
                      zIndex: -10,
                      transition: "transform 0.3s ease-in-out",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <Link
                      href="#"
                      sx={{
                        padding: "8px 16px",
                        backgroundColor: "#F26E21",
                        color: "white",
                        borderRadius: "4px",
                        textDecoration: "none",
                        '&:hover': {
                          color: 'white',
                          backgroundColor: '#d65b1c',
                        },
                      }}
                    >
                      <FaLinkedinIn />
                    </Link>
                    <Link
                      href={`mailto:${member.email}`}
                      sx={{
                        padding: "8px 16px",
                        backgroundColor: "#F26E21",
                        color: "white",
                        borderRadius: "4px",
                        textDecoration: "none",
                        '&:hover': {
                          color: 'white',
                          backgroundColor: '#d65b1c',
                        },
                      }}
                    >
                      <SiMaildotru />
                    </Link>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
};

export default AboutUs;
