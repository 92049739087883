import './PricingPage.css';
import React from 'react';
import Header from '../HeaderBar/Header';
import WithStripe from '../WithStripe/WithStripe'; // Import your WithStripe wrapper
import Copyright from '../Copyright/Copyright';
import BlackCard from '../BlackCard/BlackCard';

function PricingPage() {
  return (
    <div className="App" id="pricing-page">
      <Header />
      <WithStripe />
      <div id="black-cards-container">
        <BlackCard id="black-card-1" />
        <BlackCard id="black-card-2" />
        <BlackCard id="black-card-3" />
        <BlackCard id="black-card-4" />
      </div>
      <Copyright />
    </div>
  );
}

export default PricingPage;