import Product from "./Product";
import "./ProductPage.css";
import React from "react";
// import Header from "../HeaderBar/Header";
// import Copyright from "../Copyright/Copyright";
// import ProductPageDatabaseCard from "../ProductPageDatabaseCard/ProductPageDatabaseCard";
// import ProductPageVideoCard from "../ProductPageVideoCard/ProductPageVideoCard";
// import ProductPageMarketplaceCard from "../ProductPageMarketplaceCard/ProductPageMarketplaceCard";

function ProductPage() {
  return <Product />;
}

export default ProductPage;
