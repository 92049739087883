import { useState, useEffect } from 'react';

const useFetchCategoryGroups = () => {
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const fetchCategoryGroups = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/category_groups`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setGroups(data);
                } else {
                    throw new Error('Failed to fetch category groups');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchCategoryGroups();
    }, []);

    return groups;
};

export default useFetchCategoryGroups;