import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Plot from "react-plotly.js";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";

const CategoryTIDBoxPlot = () => {
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/get_tid_by_category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const newData = Object.keys(data).map(manufacturer => ({
          y: data[manufacturer],
          type: 'box',
          name: manufacturer
        }));
        setPlotData(newData);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <NucleonWrapper sx={{ mt: 5 }}>
      <Typography
        sx={{ fontFamily: "'Poppins', sans-serif", color: "#1C252E" }}
        variant="h6"
        gutterBottom
      >
        TID Distribution by Category
      </Typography>
      <Plot
        data={plotData}
        layout={{
          title: "TID Distribution by Category",
          yaxis: {
            title: "TID Value (krad)",
            range: [0, 300], // Ensure y-axis goes from 0 to 100%
            autorange: false,
          },
          boxmode: "group",
        }}
        style={{ width: "100%", height: "400px" }}
      />
    </NucleonWrapper>
  );
};

export default CategoryTIDBoxPlot;
