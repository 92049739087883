
import { useState, useEffect } from 'react';

const useFetchAdmin = () => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/check_admin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsAdmin(data.is_admin);
                } else {
                    console.error('Failed to check admin status');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        checkAdminStatus();
    }, []);

    return isAdmin;
};

export default useFetchAdmin;