import ContactForm from "../ContactUs/contact-us";
import "./ContactPage.css";
import React from "react";
// import Header from '../HeaderBar/Header';
// import Copyright from '../Copyright/Copyright';
// import Contact from '../Contact/Contact';
// import ContactUsBG from '../ContactUsBG/ContactUsBG';

function ContactPage() {
  return (
    <div className="App" id="contact-page">
      {/* <Header />
      <Contact />
      <ContactUsBG />
      <Copyright /> */}
      <ContactForm/>
    </div>
  );
}

export default ContactPage;