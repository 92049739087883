import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';

export const FormContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    //height: '100vh', // Ensure the container takes the full viewport height
}));

export const FormBox = styled('form')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    maxWidth: '400px',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    color: 'black',
}));

export const TextFieldStyled = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
        color: 'black',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
    },
    '& .MuiInputLabel-root': {
        color: 'black',
    },
    '& .MuiFormLabel-root': {
        color: 'black',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: 'white',
    },
    "& .MuiFilledInput-root": {
        backgroundColor: "white"
    }
}));

export const SubmitButton = styled(Button)(() => ({
    backgroundColor: 'blue',
    color: 'white',
    '&:hover': {
        backgroundColor: 'darkblue',
    },
    marginTop: '16px',
}));

export const StatusMessage = styled('div')({
    marginTop: '16px',
});
