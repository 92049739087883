import React from "react";
import { Box, Typography, Button, Breadcrumbs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3, 2),
  backgroundColor: theme.palette.background.default,
  borderBottom: "1px solid rgba(145 158 171 / 0.2)",
  fontFamily: "'Poppins', sans-serif",
}));

const Title = styled(Typography)({
  fontWeight: 600,
  fontFamily: "'Poppins', sans-serif",
});

/* eslint-disable-next-line no-unused-vars */
const CustomLink = styled(({ isLast, ...props }) => <RouterLink {...props} />)(
  (props) => ({
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.875rem",
    color: props.isLast ? "#637381" : "#1C252E",
    textDecoration: "none",
    pointerEvents: props.isLast ? "none" : "auto", // Disable interaction if it's the last link
    "&:hover": {
      textDecoration: props.isLast ? "none" : "underline", // Disable underline on hover if it's the last link
      color: "#1C252E", // Change hover color only if it's not the last link
    },
  })
);


const BreadcrumbSeparator = styled(Box)({
  width: "4px",
  height: "4px",
  backgroundColor: "#919EAB",
  borderRadius: "50%",
  margin: "0 8px",
});

const PageHeader = ({
  title,
  buttonText,
  onButtonClick,
  breadcrumbs,
  showButton = true,
}) => {
  return (
    <HeaderContainer>
      <Box>
        <Title variant="h5">{title}</Title>
        <Breadcrumbs
          separator={<BreadcrumbSeparator />}
          aria-label="breadcrumb"
          sx={{ mt: 1 }}
        >
          {breadcrumbs.map((breadcrumb, index) => (
            <CustomLink
              key={index}
              to={breadcrumb.href}
              isLast={index === breadcrumbs.length - 1}
            >
              {breadcrumb.label}
            </CustomLink>
          ))}
        </Breadcrumbs>
      </Box>

      {showButton && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onButtonClick}
          sx={{
            borderRadius: '12px',
            backgroundColor: '#F26E21',
            border: '1px solid #F26E21',
            color: '#fff',
            textAlign: 'center',
            fontSize: '14px',
            cursor: 'pointer',
            fontFamily: "'Poppins', sans-serif",
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#F26E21',
            },
          }}
        >
          {buttonText}
        </Button>


      )}
    </HeaderContainer>
  );
};

export default PageHeader;
