import { useState, useEffect } from "react";

const useFetchCategoryParts = (categoryName, attributeFilters) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchCategoryParts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/category_parts`,
          {
            method: "post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ categoryName, filters: attributeFilters }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          setRows(data);
        } else {
          throw new Error("Failed to fetch category parts");
        }
      } catch (error) {
        console.log({ type: "error", text: error.message });
      }
    };

    if (categoryName) {
      fetchCategoryParts();
    }
  }, [categoryName, attributeFilters]);

  return rows;
};

export default useFetchCategoryParts;
