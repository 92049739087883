// src/components/Layout/MainLayout.js
import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import FloatingActionButton from "../FloatingActionButton/floating-action-button";

const MainLayout = ({ children }) => {
    return (
        <>
            <Navbar />
            <main>{children}</main>
            <Footer />
            <FloatingActionButton />
        </>
    );
};

export default MainLayout;