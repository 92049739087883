import React from 'react';
import { Box, Typography, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useFetchHeavyIonExperiments from '../../../hooks/useFetchHeavyIonExperiments'; // Adjust the import path as necessary

const HeavyIonDetailsModal = ({ open, handleClose, datasourceTitle, testResultName }) => {
    const details = useFetchHeavyIonExperiments(datasourceTitle, testResultName);
    console.log("Heavy Ion Details:", details);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="heavy-ion-details-modal"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '70%',
                    backgroundColor: "white",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    alignItems: "center",
                    borderRadius: "14px",
                    padding: "20px 20px 20px 30px",
                }}
            >
                <Typography id="heavy-ion-details-modal-title" variant="h6"  sx={{fontSize:'22px',fontWeight:"500",  fontFamily: "'Poppins', sans-serif", }}  component="h2">
                    Experiment Details
                </Typography>
                <Typography  sx={{fontSize:'16px',fontWeight:"500",  fontFamily: "'Poppins', sans-serif", display:"flex",gap:3 ,mt:1}} variant="body1" component="p">
                    Datasource Title: <Typography sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }} > {datasourceTitle}  </Typography>
                </Typography>
                <Typography  sx={{fontSize:'16px',fontWeight:"500",  fontFamily: "'Poppins', sans-serif", display:"flex",gap:3,mt:1 }} variant="body1" component="p">
                    Test Result Name: <Typography sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }} >
                        {testResultName}
                        </Typography>
                </Typography> 
                <TableContainer component={Paper} sx={{ mt: 2 ,
                     backgroundColor: "white",
                     boxShadow:
                       "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                     alignItems: "center",
                     borderRadius: "14px",
                     padding: "20px 20px 20px 30px",
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}    >SN</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}  >Bias Conditions</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}  >LET</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}>Fluence</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}  >Notes</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}>Latchup Observed</TableCell>
                                <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" }}>Destructive Failure Observed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }} >{detail.sn}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }} >{JSON.stringify(detail.bias_conditions)}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }}>{detail.let}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif",color:"#637381" }}>{detail.fluence}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" ,color:"#637381"}}>{detail.notes}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" ,color:"#637381"}}>{detail.latchup_observed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell sx={{fontSize:'14px',fontWeight:"400",  fontFamily: "'Poppins', sans-serif" ,color:"#637381"}}>{detail.destructive_failure_observed ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default HeavyIonDetailsModal;
