import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';

const AnimateButton = ({ href, children, ...props }) => {
  const StyledButton = styled(Button)(() => ({
    borderRadius: '12px',
    backgroundColor: '#F26E21',
    border: 'none',
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    transition: 'all 0.5s',
    cursor: 'pointer',
    boxShadow: '0 10px 20px -8px rgba(0, 0, 0,.7)',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: "'Poppins', sans-serif",
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#F26E21',
      padding: '16px 50px ',
      color: '#fff',
    },

    '&:hover::after': {
      opacity: 1,
      right: '10px',
    },

    '&::after': {
      content: "'»'",
      fontSize: '20px',
      position: 'absolute',
      opacity: 0,
      top: '10px',
      right: '-20px',
      transition: '0.5s',
    },
  }));

  return (
    <Link
      href={href}
      passHref
      style={{ textDecoration: 'none', color: 'white' }}
    >
      <StyledButton
        // component="a"
        sx={{
          paddingBlock: { xs: 1, sm: 2 },
          paddingInline: { xs: 2, sm: 3, md: 4 },
        }}
        {...props}
      >
        {children}
      </StyledButton>
    </Link>
  );
};

export default AnimateButton;