import React, { useState } from "react";
import SectionHeader from "../SectionHeader/section-header";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQS } from "../../constants/faqs";

const FAQAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ paddingBlock: "20px", pb:14,pt:6 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <SectionHeader title="FAQs" subtitle="How can we help?" />
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: "'Poppins', sans-serif",
            color: "#3B3664",
          }}
        >
          We would love to hear from you.
        </Typography>
      </Box>
      <Grid sx={{ mt: 3 }} container spacing={3}>
        {FAQS.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color:
                        expanded === `panel${index}` ? "#F26E21" : "#3B3664",
                    }}
                  />
                }
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                  borderBottom: `${
                    expanded === `panel${index}`
                      ? "1px solid #F26E21"
                      : "1px solid #E0E0E0"
                  }`,
                  px: "0px",
                  lineHeight: 10,
                  mx: 2,
                }}
              >
                <Typography
                  sx={{
                    color: `${
                      expanded === `panel${index}` ? "#F26E21" : "#3B3664"
                    }`,
                    fontWeight: "600",
                    fontSize: "13px",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: "0px", mx: 2 }}>
                <Typography
                  sx={{
                    pt: "15px",
                    color: "gray",
                    fontWeight: "400",
                    fontSize: "13px",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {item.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FAQAccordion;
