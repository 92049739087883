import React, { useState } from "react";
import {
  CardContent,
  Typography,
  //   Table,
  //   TableBody,
  //   TableRow,
  //   TableCell,
  Grid,
  IconButton,
  Box,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditPartInformation from "../../AdminEdits/EditPartInformation";

const PartInformation = ({ partDetails, isAdmin }) => {
  const [open, setOpen] = useState(false);

  const onEdit = () => {
    console.log("Edit part details:", partDetails["Part Number"]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Box
        sx={{
          margin: 2,
          display: "flex",
          backgroundColor: "white",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          alignItems: "center",
          borderRadius: "16px",
          padding: "16px 16px 16px 24px",
        }}
      >
        <Grid container>
          {partDetails.Photo && ( // Only render this column if partDetails.Photo is not empty
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              <img
                src={partDetails.Photo}
                alt="Part"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={partDetails.Photo ? 6 : 12}
            sm={partDetails.Photo ? 8 : 12}
            md={partDetails.Photo ? 8 : 12}
          >
            <CardContent sx={{ py: 0 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 500,
                  }}
                  variant="subtitle1"
                >
                  Part Information
                </Typography>
                {isAdmin && (
                  <IconButton onClick={() => onEdit()} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      display: "flex",
                      gap: "5px",
                      minWidth: "110px",
                      textAlign: "left",
                    }}
                    component="th"
                  >
                    Manufacturer :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: "#637381",
                      display: "flex",
                    }}
                  >
                    {partDetails.Manufacturer || "No Data"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      display: "flex",
                      gap: "5px",
                      minWidth: "110px",
                      textAlign: "left",
                    }}
                    component="th"
                  >
                    Part Number :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: "#637381",
                      display: "flex",
                    }}
                  >
                    {partDetails["Part Number"] || "No Data"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      display: "flex",
                      gap: "5px",
                      minWidth: "110px",
                      textAlign: "left",
                    }}
                    component="th"
                  >
                    Category :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: "#637381",
                      display: "flex",
                    }}
                  >
                    {partDetails.Category || "No Data"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      display: "flex",
                      gap: "5px",
                      minWidth: "110px",
                      textAlign: "left",
                    }}
                    component="th"
                  >
                    Description :
                  </Typography>
                  <Typography
                    sx={{
                      width: "120px",
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: "#637381",
                      display: "flex",
                    }}
                  >
                    {partDetails.Description || "No Data"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-test-result-modal"
        aria-describedby="edit-test-result-form"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width:"500px"
          }}
        >
          <EditPartInformation
            partNumber={partDetails["Part Number"]}
            manufacturer={partDetails.Manufacturer}
            onClose={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default PartInformation;
