import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Plot from "react-plotly.js";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";

const TIDCumulativePlot = () => {
  const [plotData, setPlotData] = useState({});
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/get_HI_cumulative_plot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => setPlotData(data))
      .catch(error => console.error('Error:', error));
  }, []);

  const data = [
    {
      x: Object.keys(plotData),
      y: Object.values(plotData),
      mode: "lines+markers",
      type: "scatter",
      marker: {
        color: "red",
      },
    },
  ];

  const layout = {
    title: "HI Pass Percentage - Commercial Parts",
    xaxis: {
      title: "LET (MeV-cm^2/mg)",
    },
    yaxis: {
      title: "Percentage",
      range: [0, 100], // Ensure y-axis goes from 0 to 100%
    },
    autosize: true,
  };

  return (
    <NucleonWrapper sx={{ mt: 5 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontFamily: "'Poppins', sans-serif", color: "#1C252E" }}
      >
        Cumulative HI Pass Percentage
      </Typography>
      {Object.keys(plotData).length > 0 && (
        <Plot
          data={data}
          layout={layout}
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "#1E293A",
            borderRadius: "10px",
          }}
        />
      )}
    </NucleonWrapper>
  );
};

export default TIDCumulativePlot;
