import React from "react";
import { Box } from "@mui/material";
import PageWrapper from "../Dashboard/PageWrapper/page-wrapper";

const DashboardLayout = () => {
  return (
    <Box>
      <PageWrapper />
    </Box>
  );
};

export default DashboardLayout;
