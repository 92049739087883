import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";

const PartsPerYear = () => {
  const [plotDetails, setPlotDetails] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/get_results_per_year`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include any credentials here if necessary
    })
      .then(response => response.json())
      .then(data => setPlotDetails(data))
      .catch(error => console.error('Error:', error));
  }, []);
  // Prepare data for Plotly
  const data = [
    {
      x: Object.keys(plotDetails), // Years
      y: Object.values(plotDetails), // Number of parts
      type: "bar",
      marker: { color: "blue" }, // Customize bar color here
    },
  ];

  const layout = {
    title: "Parts Per Year",
    xaxis: {
      title: "Year",
    },
    yaxis: {
      title: "Number of Parts",
    },
    autosize: true,
    margin: {
      l: 50,
      r: 50,
      b: 100,
      t: 100,
      pad: 4,
    },
  };

  return (
    <NucleonWrapper sx={{ mt: 5 }}>
      {Object.keys(plotDetails).length > 0 && (
        <Plot
          data={data}
          layout={layout}
          style={{
            width: "100%",
            height: "100%",
            fontFamily: "'Poppins', sans-serif",
            color: "#1C252E",
          }}
        />
      )}
    </NucleonWrapper>
  );
};

export default PartsPerYear;
