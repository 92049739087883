// src/App.js
import "./App.css";
import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import AboutPage from "./components/AboutPage/AboutPage";
import PricingPage from "./components/PricingPage/PricingPage";
import CallbackPage from "./components/CallbackPage/CallbackPage";
import ContactPage from "./components/ContactPage/ContactPage";
import ProductPage from "./components/ProductPage/ProductPage";
import TestSolutionPage from "./components/TestSolutionsPage/TestSolutionPage";
import MainLayout from "./components/Layout/Website";
import DashboardRoutes from "./Router/Dashboard";
import BlogList from './components/Blogs/Blogs';
import BlogPostPage from './components/Blogs/BlogPostPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
        <Route
          path="/about"
          element={
            <MainLayout>
              <AboutPage />
            </MainLayout>
          }
        />
        <Route
          path="/products"
          element={
            <MainLayout>
              <ProductPage />
            </MainLayout>
          }
        />
        <Route
          path="/test-solutions"
          element={
            <MainLayout>
              <TestSolutionPage />
            </MainLayout>
          }
        />
        <Route
          path="/pricing"
          element={
            <MainLayout>
              <PricingPage />
            </MainLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <MainLayout>
              <ContactPage />
            </MainLayout>
          }
        />
        <Route
          path="/blogs"
          element={
            <MainLayout>
              <BlogList />
            </MainLayout>
          }
        />
        <Route
          path="blogs/:slug"
          element={
            <MainLayout>
              <BlogPostPage />
            </MainLayout>
          }
        />
        <Route
          path="/callback"
          element={
            <MainLayout>
              <CallbackPage />
            </MainLayout>
          }
        />

        <Route path="/dashboard/*" element={<DashboardRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;
