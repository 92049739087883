import React from "react";
import blogs from "./blogs.json";
import { Navigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Grid,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import SectionHeader from "../SectionHeader/section-header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

// Utility function to render various types of content, excluding videos
const renderContent = (content) => {
  return content
    .filter((element) => element.type !== "video")
    .map((element, index) => {
      switch (element.type) {
        case "header":
          return (
            <Typography
              sx={{
                color: "#1C252E",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "16px",
              }}
              variant="h6"
              key={index}
              gutterBottom
            >
              {element.value}
            </Typography>
          );
        case "subheading":
          return (
            <Typography
              sx={{ color: "#1C252E", fontFamily: "'Poppins', sans-serif" }}
              variant="subtitle1"
              key={index}
              gutterBottom
            >
              {element.value}
            </Typography>
          );
        case "paragraph":
          return (
            <Typography
              variant="body2"
              key={index}
              paragraph
              sx={{ color: "#637381", fontFamily: "'Poppins', sans-serif" }}
            >
              {element.value}
            </Typography>
          );
        case "list":
          return element.ordered ? (
            <ol style={{ paddingLeft: 16 }} key={index}>
              {element.items.map((item, itemIndex) => (
                <li
                  style={{
                    color: "#1C252E",
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "14px",
                    lineHeight: 1.6,
                  }}
                  key={itemIndex}
                >
                  {item}
                </li>
              ))}
            </ol>
          ) : (
            <ul style={{ paddingLeft: 16 }} key={index}>
              {element.items.map((item, itemIndex) => (
                <li
                  style={{
                    color: "#1C252E",
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "14px",
                    lineHeight: 1.5,
                  }}
                  key={itemIndex}
                >
                  {item}
                </li>
              ))}
            </ul>
          );
        default:
          return null;
      }
    });
};

// Styled components
const StatusChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 1,
  backgroundColor: "#e0f7fa",
  color: "#00796b",
}));

const TextContainer = styled(Box)(() => ({
  overflow: "hidden",
  position: "relative",
  maxHeight: "108px",
  "&::after": {
    content: '""',
    width: "100%",
    height: "24px",
    position: "absolute",
    bottom: "0",
    left: "0",
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
  },
}));

const BlogList = () => {
  if (blogs?.length === 0) {
    return <Navigate to="/" />;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ fontFamily: "Poppins, sans-serif", py: 3, my: 5 }}
    >
      <SectionHeader
        title="Blogs"
        subtitle="Updates from Nucleon"
      />
      <Box sx={{ my: 5 }}>
        <Grid container spacing={3}>
          {blogs.map((blog) => {
            const imageElement = blog.content.find((el) => el.type === "image");
            const imageSrc = imageElement
              ? imageElement.src
              : "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png";
            const imageAlt = imageElement
              ? imageElement.alt
              : "Placeholder Image";

            return (
              <Grid item xs={12} sm={6} md={4} lg={4} key={blog.id}>
                <Link
                  to={`/blogs/${blog.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      padding: "8px",
                      borderRadius: "20px",
                      overflow: "hidden",
                      backgroundColor: "white",
                      boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      height: "100%",
                      transition:
                        "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: "0px 8px 25px rgba(0,0,0,0.15)",
                      },
                    }}
                  >
                    <LazyLoadImage
                      placeholderSrc="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                      effect="blur"
                      alt={imageAlt}
                      wrapperProps={{
                        style: { transitionDelay: "1s" },
                      }}
                      src={imageSrc}
                      style={{
                        objectFit: "cover",
                        borderRadius: "12px",
                        width: "100%",
                        height: "220px",
                      }}
                    />
                    <StatusChip label="Published" />
                    <CardContent
                      sx={{
                        height: "200px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography
                          fontWeight="400"
                          fontSize="14px"
                          color="textSecondary"
                        >
                          12 Sept 2024
                        </Typography>
                        <Avatar
                          sx={{ width: 30, height: 30 }}
                          src="https://example.com/avatar.jpg"
                        />
                      </Box>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          color: "#1C252E",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: "600",
                        }}
                      >
                        {blog.title}
                      </Typography>
                      <TextContainer>
                        {renderContent(
                          blog.content.filter((item) => item.type !== "image")
                        )}
                      </TextContainer>
                    </CardContent>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};

export default BlogList;