import { useState } from 'react';

const useEditUser = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const editUser = async (payload) => {
        setStatusMessage(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/edit-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            });
            console.log("Submitted payload: ", payload)
            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'User updated successfully!' });
                window.location.reload(); // Force a refresh of the page
            } else {
                throw new Error(data.message || 'Failed to update user.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { editUser, statusMessage };
};

export default useEditUser;
