import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  //   TableCell,
  Box,
  IconButton,
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

const PartParametricInfo = ({ partDetails, isAdmin }) => {
  const { partNumber } = useParams(); // Using `useParams` to access the part number
  const [parametrics, setParametrics] = useState([]);

  const handleEditClick = async () => {
    console.log('Fetching Digikey Details');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/partdetails/refresh_params`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            partNumber: partDetails['Part Number'],
            manufacturer: partDetails.Manufacturer,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log('Refreshed part details:', data);
        window.location.reload(); // Reload the page
      } else {
        throw new Error('Failed to refresh parameters');
      }
    } catch (error) {
      console.log({ type: 'error', text: error.message });
    }
  };

  useEffect(() => {
    const fetchParametrics = () => {
      console.log('Fetching data for part number:', partNumber);
      fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/partdetails/parametrics`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ partNumber }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('Data fetched:', data);
          setParametrics(data);
        })
        .catch((error) =>
          console.error('Error fetching parametric data:', error)
        );
    };

    // Check if partNumber is valid before fetching
    if (partNumber) {
      fetchParametrics();
    }
  }, [partNumber]); // Depend on partNumber to refetch when it changes

  return (
    <Box
      sx={{
        margin: 2,
        display: 'flex',
        backgroundColor: 'white',
        boxShadow:
          'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        alignItems: 'center',
        borderRadius: '16px',
        padding: '16px 16px 16px 24px',
      }}
      id="part-parametric-info-card"
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: 22,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
            }}
            variant="subtitle1"
            id="part-parametric-info-title"
          >
            Part Parametric Info
          </Typography>
          {isAdmin && (
            <IconButton
              onClick={handleEditClick}
              aria-label="edit"
              id="edit-icon-button"
            >
              <UpdateIcon />
            </IconButton>
          )}
        </Box>
        <Table>
          <TableBody>
            {parametrics.length > 0 ? (
              parametrics.map((param, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  {/* Uncomment below if the 'Group' column needs to be displayed */}
                  {/* <TableCell style={{ textAlign: 'right' }}>{param.Group}</TableCell> */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      display: 'flex',
                      gap: '5px',
                      minWidth: '110px',
                      textAlign: 'left',
                    }}
                    component="th"
                  >
                    {param.Name}:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: '#637381',
                      display: 'flex',
                      textAlign: 'end',
                    }}
                  >
                    {param.Value || 'No Data'}
                  </Typography>
                </Box>
              ))
            ) : (
              <TableRow>
                <Typography
                  colSpan={3}
                  id="no-parametric-data"
                  sx={{
                    textAlign: 'center',
                    fontSize: 16,
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    color: '#637381',
                    mt: 2,
                  }}
                >
                  No Parametric Data Available
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Box>
  );
};

export default React.memo(PartParametricInfo);