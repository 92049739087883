import React from 'react';
import './MarketplaceSubCard.css';

const MarketplaceSubCardComponent = () => {
  return (
    <div className="container-black-m card text-center text-white">
      <div className="card-body">
        <p className="card-text fs-5 mb-3">535 new test results</p>
        <p className="card-text fs-5 mb-3">coming soon in 2024</p>
    </div>
    </div>
  );
};

export default MarketplaceSubCardComponent;