import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';

function CategoryDetails() {
    console.log(useParams())
    const { categoryName } = useParams(); // This captures the category name from the URL
    const [category, setCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    console.log(categoryName);

    useEffect(() => {
        const fetchCategoryDetails = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/category_details`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ categoryName: categoryName }),
                        credentials: 'include'
                    });
                if (!response.ok) {
                    throw new Error('Failed to fetch category details');
                }
                const data = await response.json();
                setCategory(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategoryDetails();
    }, [categoryName]); // Dependency on categoryName to refetch if it changes

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!category) return <div>No category found.</div>;

    return (
        <Paper elevation={3} sx={{ padding: 3, margin: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Category Modely: {category.name}
            </Typography>
            <Typography variant="h5" gutterBottom>
                Group: {category.groupName}
            </Typography>
            <Typography variant="body1" gutterBottom>
                Number of MPNs in Category: {category.mpnCount}
            </Typography>
            {/* Additional details can be rendered here */}
            {/* Use Typography for text elements for better control over styling */}
        </Paper>
    );
}

export default CategoryDetails;
