import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../Loader/loader";
function CallbackPage() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const hasExchangedCode = useRef(false);

  useEffect(() => {
    if (!hasExchangedCode.current) {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      if (code) {
        hasExchangedCode.current = true;

        fetch(`${process.env.REACT_APP_API_URL}/auth/exchange_code`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: code }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            navigate("/dashboard"); // Use navigate function here
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }, [location, navigate]); // Add navigate to this array

  return <Loader />;
}

export default CallbackPage;
