import React from "react";
import {
  Box,
  CardContent,
  Typography,
  // Table, TableBody, TableRow, TableCell
} from "@mui/material";

const PartRadiationSummary = ({ partDetails }) => (
  <Box
    sx={{
      margin: 2,
      display: "flex",
      backgroundColor: "white",
      boxShadow:
        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
      alignItems: "center",
      borderRadius: "16px",
      padding: "16px 16px 16px 24px",
      mt: 5,
    }}
  >
    <CardContent sx={{ p: 0 }}>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: 22,
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 500,
        }}
        variant="subtitle1"
      >
        {" "}
        Radiation Performance Summary
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: 16,
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 400,
        }}
        variant="subtitle1"
        gutterBottom
      >
        Information On Tested Limit for Total-Ionizing Dose and Heavy-Ion Single
        Event Testing
      </Typography>
      {/* <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><b>Total-Ionizing Dose</b></TableCell>
                        <TableCell style={{ width: '85%' }}>
                            {partDetails["Max Passing TID"] ? `${partDetails["Max Passing TID"]} krad` : 'No Data'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row" style={{ width: '15%', textAlign: 'right' }}><b>Heavy-Ion</b></TableCell>
                        <TableCell style={{ width: '85%' }}>
                            {partDetails["Max Passing LET"] ? (
                                <span dangerouslySetInnerHTML={{ __html: `${partDetails["Max Passing LET"]} MeV-cm<sup>2</sup>/mg` }} />
                            ) : 'No Data'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table> */}
      <Box
        sx={{ mt: 2.5, display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              display: "flex",
              gap: "5px",
              minWidth: "150px",
              textAlign: "left",
            }}
            component="th"
          >
            Total-Ionizing Dose
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 400,
              color: "#637381",
              display: "flex",
            }}
          >
            {partDetails["Max Passing TID"]
              ? `${partDetails["Max Passing TID"]} krad`
              : "No Data"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              display: "flex",
              gap: "5px",
              minWidth: "150px",
              textAlign: "left",
            }}
            component="th"
          >
            Heavy-Ion
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 400,
              color: "#637381",
              display: "flex",
            }}
          >
            {partDetails["Max Passing LET"] ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${partDetails["Max Passing LET"]} MeV-cm<sup>2</sup>/mg`,
                }}
              />
            ) : (
              "No Data"
            )}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Box>
);

export default PartRadiationSummary;
