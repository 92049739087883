import React from 'react';
import RadiationIntake from '../RadiationIntakeForm/radiation-intake';
import { Box, Container } from '@mui/material';
import TestSolutions from '../TestSolutions/test-solutions';

const sections = [
  { Component: TestSolutions, name: "Test Solutions" },
  { Component: RadiationIntake, name: "Intake" }
];

function TestSolutionsPage() {

  return sections.map(({ Component, name }, index) => {
    const isHero = name === "Hero";
    return (
      <Box
        key={name}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          width: "100%",
          backgroundColor: index % 2 !== 0 ? "#f9f9fa" : "#ffffff",
        }}
      >
        <Container
          sx={{ px: { xs: 3, sm: 4 }, transform: { lg: "scale(0.9)", xl: "scale(1)" } }}
          maxWidth={isHero ? false : "xl"}
        >
          <Component />
        </Container>
      </Box>
    );
  });
}

export default TestSolutionsPage;
