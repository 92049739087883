import React from 'react';
import { Container, Grid, Box, Typography, Divider } from '@mui/material';
import SectionHeader from '../SectionHeader/section-header';
import AnimateButton from '../Button/AnimateButton';
import { EmojiEmotionsOutlined, PersonOutline } from '@mui/icons-material';

const sections = [
    {
        title: "Nucleon Radiation Testing",
        description: [
            "Subscribers have the opportunity to participate in quarterly test campaigns.",
            "Receive discounts by allowing publication of test data.",
            "Leverage our partnerships with multiple facilities for heavy-ion, proton, total-ionizing dose, and displacement damage testing."
        ],
        imageUrl: "https://via.placeholder.com/200",
        linkLabel: "SUBSCRIBE TODAY",
        linkUrl: "#",
        alternate: false,
    },
    {
        title: "Access up-to-date radiation test data",
        description: [
            "Curated compilation of test results from public and private sources.",
            "Categorized data with key results extraction.",
            "Searchable database for easy access to relevant data."
        ],
        imageUrl: "https://via.placeholder.com/200",
        linkLabel: "LEARN MORE",
        linkUrl: "#",
        alternate: true,
    },
];

const TestSolutions = () => {
    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 10, gap: 4, pb: 10 }}>
            <SectionHeader
                title="Test Solutions"
                subtitle="Radiation Testing"
            />
            <Grid container spacing={14} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                {sections.map((section, index) => (
                    <React.Fragment key={index}>
                        {section.alternate ? (
                            <>
                                <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ position: 'relative', alignSelf: 'center' }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: 4,
                                        }}
                                        alt={section.title}
                                        src='TestingPic1.jpg'
                                    />

                                    {/* <FloatingBox position="top-right" content={
                                        <Box sx={{ padding: 1, }}>
                                            <Typography sx={{ fontWeight: 700, fontSize: 18, padding: 1.5, fontFamily: "'Poppins', sans-serif", }}>Testimonials</Typography>
                                            <Divider sx={{ borderBottomWidth: '0.5px' }} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, margin: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                                                        <PersonOutline sx={{ color: '#F26E21' }} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="button" sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif", }}> 212+</Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="caption"> Client Testimonials</Typography>
                                                    </Box>
                                                </Box>
                                                <Divider sx={{ borderBottomWidth: '0.5px', marginInline: 1, my: 1 }} />
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                                                        <EmojiEmotionsOutlined sx={{ color: '#F26E21' }} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="button" sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif", }}> 5000+</Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="caption"> Happy Clients</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    } /> */}
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                                    <Content section={section} />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Content section={section} />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ position: 'relative', alignSelf: 'center' }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: 4,
                                        }}
                                        alt={section.title}
                                        src='TestingPic2.jpg'
                                    />
                                    {/* <FloatingBox position="top-right" content={
                                        <Box sx={{ padding: 1, }}>
                                            <Typography sx={{ fontWeight: 700, fontSize: 18, padding: 1.5, fontFamily: "'Poppins', sans-serif" }}>Testimonials</Typography>
                                            <Divider sx={{ borderBottomWidth: '0.5px' }} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, margin: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                                                        <PersonOutline sx={{ color: '#F26E21' }} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="button" sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif", }}> 212+</Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="caption"> Client Testimonials</Typography>
                                                    </Box>
                                                </Box>
                                                <Divider sx={{ borderBottomWidth: '0.5px', marginInline: 1, my: 1 }} />
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                                                        <EmojiEmotionsOutlined sx={{ color: '#F26E21' }} />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="button" sx={{ fontWeight: 700, fontFamily: "'Poppins', sans-serif", }}> 5000+</Typography>
                                                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="caption"> Happy Clients</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    } /> */}
                                </Grid>
                            </>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Container>
    );
};

// const FloatingBox = ({ position, content }) => {
//     const getPositionStyles = () => {
//         switch (position) {
//             case 'bottom-left':
//                 return {
//                     top: '10%',
//                     left: '10%',
//                     transform: 'translateX(50%) translateY(-50%)'
//                 };
//             case 'top-right':
//                 return {
//                     top: '90%',
//                     right: '10%',
//                     transform: 'translateX(50%) translateY(-50%)'
//                 };
//             default:
//                 return {};
//         }
//     };

//     return (
//         <Box
//             sx={{
//                 position: 'absolute',
//                 bgcolor: 'background.paper',
//                 boxShadow: 3,
//                 borderRadius: 4,
//                 minWidth: 200,
//                 ...getPositionStyles(),
//                 '@media (max-width: 1024px)': {
//                     visibility: 'hidden',
//                 }
//             }}
//         >
//             <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="body2">{content}</Typography>
//         </Box>
//     );
// };

const Content = ({ section }) => {
    return (
        <Box
            sx={{
                borderRadius: 1,
                position: 'relative',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="h4" gutterBottom>
                {section.title}
            </Typography>
            <Box sx={{ mt: 4 }}>
                {section.description.map((line, idx) => (
                    <Box key={idx} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 1 }}>
                        <Box sx={{ minWidth: 8, maxwidth: 8, minHeight: 8, maxheight: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F26E21', borderRadius: '50%', mt: 1 }} />
                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} variant="body1" paragraph >{line}</Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{ padding: 1, visibility: 'flex', '@media (min-width: 1024px)': { display: 'none', flexDirection: 'column' } }}>
                <Typography sx={{ fontWeight: 700, fontSize: 18, padding: 1.5 }}>Testimonials</Typography>
                <Divider sx={{ borderBottomWidth: '0.5px' }} />
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1, sm: 2, md: 3 }, marginTop: 2, marginBottom: 6 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                            <PersonOutline sx={{ color: '#F26E21' }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="button" sx={{ fontWeight: 700 }}> 212+</Typography>
                            <Typography variant="caption"> Client Testimonials</Typography>
                        </Box>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(215, 118, 7, 0.3)', borderRadius: '50%' }}>
                            <EmojiEmotionsOutlined sx={{ color: '#F26E21' }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="button" sx={{ fontWeight: 700 }}> 5000+</Typography>
                            <Typography variant="caption"> Happy Clients</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ position: { lg: 'absolute' }, bottom: 0, left: 0 }}>
                <AnimateButton>
                    {section.linkLabel}
                </AnimateButton>
            </Box>
        </Box>
    );
};

export default TestSolutions;