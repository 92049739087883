import { useState, useEffect } from 'react';

const useFetchManufacturers = () => {
    const [manufacturers, setManufacturers] = useState([]);

    useEffect(() => {
        const fetchManufacturers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/manufacturers`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setManufacturers(data);
                } else {
                    throw new Error('Failed to fetch manufacturers');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchManufacturers();
    }, []);

    return manufacturers;
};

export default useFetchManufacturers;