import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import PartInformation from './PartInformation';
import PartRadiationSummary from './PartRadiationSummary';
import PartDetailsTestResults from './PartDetailsTestResults';
import PartParametricInfo from './PartParametricInfo';

// Hooks
import useFetchAdmin from '../../../hooks/useFetchAdmin';
import useFetchPartDetails from '../../../hooks/useFetchPartDetails';
//import { PART_DETAILS } from '../../../mock/partdetails';
import PageHeader from '../PageHeader/page-header';

const PartDetails = () => {
    const { manufacturer, partNumber } = useParams();
    const isAdmin = useFetchAdmin();
    const { partDetails, similarParts, isLoading, error } = useFetchPartDetails(manufacturer, partNumber); // eslint-disable-line
    //const partDetails = PART_DETAILS;

    return (
        <Box>
            <PageHeader
                title={`Part Details: ${manufacturer} ${partNumber}`}
                breadcrumbs={[
                    { label: 'Dashboard', href: '/dashboard' },
                    { label: partNumber, href: `/dashboard/parts/${manufacturer}/${partNumber}` },
                ]}
                showButton={false}
            />
            {partDetails && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PartInformation partDetails={partDetails} isAdmin={isAdmin} />
                            <PartParametricInfo partDetails={partDetails} isAdmin={isAdmin} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PartRadiationSummary partDetails={partDetails} />
                            <PartDetailsTestResults testResults={partDetails["Test Results"]} isAdmin={isAdmin} />
                        </Grid>
                    </Grid>
                    {/* {similarParts && similarParts.length > 0 && (
                        <SimilarParts similarParts={similarParts} />
                    )} */}
                </>
            )}
        </Box>
    );
};

export default PartDetails;