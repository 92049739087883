import React from "react";
import { AppBar, Toolbar, Box, IconButton } from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutButton from "../../LogoutButton/LogoutButton";
import { useNavigate } from "react-router-dom";
const NucleonAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Grow = styled("div")({
  flexGrow: 1,
});

const IconsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const IconButtonStyled = styled(IconButton)(() => ({}));

const DashboardAppBar = ({ handleToggleSidebar }) => {
  const navigate = useNavigate();
  return (
    <NucleonAppBar position="fixed">
      <Toolbar>
        <IconButton
          onClick={() => handleToggleSidebar()}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuOutlined sx={{ color: "black" }} />
        </IconButton>
        <Grow />
        <IconsBox>
          <LogoutButton />
          <IconButtonStyled onClick={() => navigate(`/dashboard/account`)}>
            <AccountCircleRoundedIcon sx={{ width: 30, height: 30 }} />
          </IconButtonStyled>
        </IconsBox>
      </Toolbar>
    </NucleonAppBar>
  );
};

export default DashboardAppBar;
