import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { sanitizeInput } from "../../helper/helper";

const AddDigikeyPart = () => {
  const [digiKeyPartNumber, setDigiKeyPartNumber] = useState("");
  const [statusMessage, setStatusMessage] = useState(null);

  const handleChange = (e) => {
    setDigiKeyPartNumber(sanitizeInput(e.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatusMessage(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/operations/add_digikey_part`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ digiKeyPartNumber }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setStatusMessage({ type: "success", text: "Part added successfully!" });
      } else {
        throw new Error(data.message || "Failed to add part");
      }
    } catch (error) {
      setStatusMessage({ type: "error", text: error.message });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}

    >
      {/* <label style={{ display: 'block', marginBottom: '10px' }}>
                Digi-Key Part Number:
                <input
                    type="text"
                    name="digiKeyPartNumber"
                    value={digiKeyPartNumber}
                    onChange={handleChange}
                    style={{ display: 'block', margin: '5px 0', padding: '8px', width: '100%', backgroundColor: 'white', color: 'black' }}
                />
            </label> */}

      <TextField
        style={{
          width: "100%",
          marginRight: "0",
          marginBottom: "10px",
        }}
        label=" Digi-Key Part Number:"
        type="text"
        name="digiKeyPartNumber"
        value={digiKeyPartNumber}
        onChange={handleChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Set focus border color to black
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)", // Initial label color
            "&.Mui-focused": {
              color: "black", // Label color on focus
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
            opacity: 1, // Ensure full opacity
          },
          "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
          },
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",

            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21", // Keep the same background on hover
              boxShadow: "none", // Remove box-shadow on hover if any
            },
            "&:active": {
              backgroundColor: "#F26E21", // Keep the same background on click
              boxShadow: "none", // Remove box-shadow on click if any
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21", // Keep the same background on focus
              boxShadow: "none", // Remove box-shadow on focus if any
            },
          }}
        >
          Submit
        </Button>
      </Box>
      {statusMessage && (
        <div
          style={{
            color: statusMessage.type === "success" ? "green" : "red",
            marginTop: "10px",
          }}
        >
          {statusMessage.text}
        </div>
      )}
    </form>
  );
};

export default AddDigikeyPart;
