/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Box, Typography, Link } from "@mui/material";
import useFetchCategoryGroups from "../../../hooks/useFetchCategoryGroups";
import useFetchCategoriesByGroup from "../../../hooks/useFetchCategoriesByGroup";
import useFetchCategoryDetails from "../../../hooks/useFetchCategoryDetails";
import useFetchCategoryParts from "../../../hooks/useFetchCategoryParts";
import "./CategoryModels.css";
import PageHeader from "../PageHeader/page-header";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";
import NucleonDataGrid from "../NucleonDataGrid/nucleon-data-grid";
import { useNavigate } from "react-router-dom";

function CategoryModels() {
  const navigate = useNavigate();
  const groups = useFetchCategoryGroups();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupCategories, setGroupCategories] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [attributeFilters, setAttributeFilters] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const fetchedGroupCategories = useFetchCategoriesByGroup(selectedGroup);
  const fetchedCategoryDetails = useFetchCategoryDetails(selectedCategory);
  const fetchedCategoryParts = useFetchCategoryParts(
    selectedCategory,
    attributeFilters
  );

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([
    { field: "Part Number", headerName: "Part Number", width: 150 },
    { field: "Manufacturer", headerName: "Manufacturer", width: 150 },
    {
      field: "details",
      headerName: "Details",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Link
          href={`/dashboard/parts/${params.row.Manufacturer}/${params.row["Part Number"]}`}
          target="_blank"
        >
          View Details
        </Link>
      ),
    },
  ]);

  useEffect(() => {
    if (selectedGroup) {
      setGroupCategories(fetchedGroupCategories);
      setSelectedCategory(null);
    } else {
      setGroupCategories([]);
    }
  }, [selectedGroup, fetchedGroupCategories]);

  useEffect(() => {
    if (selectedCategory) {
      setCategoryDetails(fetchedCategoryDetails);
    } else {
      setCategoryDetails(null);
    }
  }, [selectedCategory, fetchedCategoryDetails]);

  useEffect(() => {
    if (fetchedCategoryParts) {
      console.log("Fetched Category Parts:", fetchedCategoryParts); // Debug log
      const updatedRows = fetchedCategoryParts.rows || [];
      console.log("Updated Rows:", updatedRows); // Debug log

      const attributeColumns = (fetchedCategoryParts.columns || []).filter(
        (col) => col.field !== "details"
      );
      const detailsColumn = {
        field: "details",
        headerName: "Details",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <NucleonButton
            isActive={true}
            handleButtonClick={() =>
              navigate(
                `/dashboard/parts/${encodeURIComponent(
                  params.row.Manufacturer
                )}/${encodeURIComponent(params.row["Part Number"])}`
              )
            }
          >
            View Details
          </NucleonButton>
        ),
      };

      setRows(updatedRows);
      setColumns([...attributeColumns, detailsColumn]);
    } else {
      setRows([]);
      setColumns([
        { field: "Part Number", headerName: "Part Number", width: 150 },
        { field: "Manufacturer", headerName: "Manufacturer", width: 150 },
        {
          field: "details",
          headerName: "Details",
          width: 150,
          sortable: false,
          filterable: false,
          renderCell: (params) => (
            <Link
              href={`/dashboard/parts/${params.row.Manufacturer}/${params.row["Part Number"]}`}
              target="_blank"
            >
              View Details
            </Link>
          ),
        },
      ]);
    }
  }, [fetchedCategoryParts, attributeFilters]);

  const handleAttributeChange = (index, value, type) => {
    setAttributeFilters((prevFilters) => ({
      ...prevFilters,
      [index]: { value, type },
    }));
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%", height: `calc(100vh - ${90}px)` }}
    >
      <PageHeader
        title="Category Models"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Category Models", href: "/dashboard/category-models" },
        ]}
        showButton={false}
      />
      <Autocomplete
        id="group-select-autocomplete"
        options={groups}
        getOptionLabel={(option) => option.groupName || ""}
        value={selectedGroup}
        onChange={(event, newValue) => {
          setSelectedGroup(newValue);
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Set focus border color to black
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)", // Initial label color
            "&.Mui-focused": {
              color: "black", // Label color on focus
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
            opacity: 1, // Ensure full opacity
          },
          "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a Category Group"
            variant="outlined"
            fullWidth
          />
        )}
        style={{ marginBottom: "20px", color: "black" }}
      />

      {selectedGroup && (
        <Autocomplete
          id="category-select-autocomplete"
          options={groupCategories}
          getOptionLabel={(option) => option.name || ""}
          value={selectedCategory}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black", // Set focus border color to black
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
              borderColor: "rgba(145, 158, 171, 0.2)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(145 158 171)", // Initial label color
              "&.Mui-focused": {
                color: "black", // Label color on focus
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
              opacity: 1, // Ensure full opacity
            },
            "& .MuiInputBase-input:focus::placeholder": {
              color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Category"
              variant="outlined"
              fullWidth
            />
          )}
          style={{ marginBottom: "20px", color: "black" }}
        />
      )}

      {selectedCategory && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Box sx={{ mb: 5 }}>
            <Typography
              sx={{
                fontSize: 24,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 600,
                mb: 1,
              }}
            >
              Category: {selectedCategory.name}
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                mb: 1,
              }}
            >
              Relevant Attributes:
            </Typography>
          </Box>
          <Box
            display="flex"
            // overflow="auto"
            whiteSpace="nowrap"
            sx={{
              width: "100%",
              py: 2,
              marginBottom: "40px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {categoryDetails && categoryDetails.attributes ? (
              categoryDetails.attributes.map((attribute, index) => (
                <NucleonWrapper
                  key={index}
                  style={{ margin: "0 0 0 0", width: "90%" }}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 500,
                      mb: 2,
                    }}
                  >
                    {attribute.name}
                  </Typography>
                  {attribute.type === "float" ? (
                    <>
                      <TextField
                        label="Min"
                        style={{
                          width: "48%",
                          marginRight: "0",
                          marginBottom: "10px",
                        }}
                        id={`float-min-${index}`}
                        onChange={(e) =>
                          handleAttributeChange(
                            `min-${attribute.name}`,
                            e.target.value,
                            "min"
                          )
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black", // Set focus border color to black
                            },
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            borderColor: "rgba(145, 158, 171, 0.2)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(145 158 171)", // Initial label color
                            "&.Mui-focused": {
                              color: "black", // Label color on focus
                            },
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                            opacity: 1, // Ensure full opacity
                          },
                          "& .MuiInputBase-input:focus::placeholder": {
                            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                          },
                        }}
                      />
                      <TextField
                        label="Max"
                        style={{ width: "48%", marginLeft: "15px" }}
                        id={`float-max-${index}`}
                        onChange={(e) =>
                          handleAttributeChange(
                            `max-${attribute.name}`,
                            e.target.value,
                            "max"
                          )
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black", // Set focus border color to black
                            },
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            borderColor: "rgba(145, 158, 171, 0.2)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(145 158 171)", // Initial label color
                            "&.Mui-focused": {
                              color: "black", // Label color on focus
                            },
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                            opacity: 1, // Ensure full opacity
                          },
                          "& .MuiInputBase-input:focus::placeholder": {
                            color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                          },
                        }}
                      />
                    </>
                  ) : (
                    <TextField
                      label="Value"
                      style={{ width: "100%", marginLeft: "0" }}
                      id={`string-value-${index}`}
                      onChange={(e) =>
                        handleAttributeChange(
                          `value-${attribute.name}`,
                          e.target.value,
                          "value"
                        )
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2,
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black", // Set focus border color to black
                          },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          borderColor: "rgba(145, 158, 171, 0.2)",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(145 158 171)", // Initial label color
                          "&.Mui-focused": {
                            color: "black", // Label color on focus
                          },
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                          opacity: 1, // Ensure full opacity
                        },
                        "& .MuiInputBase-input:focus::placeholder": {
                          color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
                        },
                      }}
                    />
                  )}
                </NucleonWrapper>
              ))
            ) : (
              <p>No attributes available.</p>
            )}
          </Box>
          <NucleonDataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => `${row["Part Number"]}_${row.Manufacturer}`}
            total={rows.length}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      )}
    </Box>
  );
}

export default CategoryModels;
