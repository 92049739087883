import React, { useState, useEffect } from "react";
import {
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import useFetchUsers from "../../hooks/useFetchUsers";
import useEditUser from "../../hooks/useEditUser";
import useFetchUserDetails from "../../hooks/useFetchUserDetails";
import { FormContainer, StatusMessage } from "../Admin/AdminCss";
import { sanitizeInput } from "../../helper/helper";

const EditUserForm = () => {
  // Setup autocomplete options for users
  const users = useFetchUsers();
  const [selectedUser, setSelectedUser] = useState(null);

  // Initialize userDetails state
  const [userDetails, setUserDetails] = useState({
    subscriptionGroup: "",
    affiliation: "",
  });

  const fetchedUserDetails = useFetchUserDetails(
    selectedUser ? selectedUser : null
  );
  const { editUser, statusMessage } = useEditUser();

  useEffect(() => {
    if (selectedUser) {
      // Update userDetails state when selectedUser changes
      console.log(selectedUser);
      console.log(fetchedUserDetails);
      setUserDetails(fetchedUserDetails);
    } else {
      setUserDetails({ subscriptionGroup: "", affiliation: "" });
    }
  }, [selectedUser, fetchedUserDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Firing handleSubmit");

    const payload = {
      email: selectedUser.email, // Assuming selectedUser is an object with email property
      subscriptionGroup: userDetails.subscriptionGroup,
      affiliation: userDetails.affiliation,
    };

    await editUser(payload);
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: sanitizeInput(value) }));
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "10px",
  };

  const inputSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(145, 158, 171, 0.8)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
      borderColor: "rgba(145, 158, 171, 0.2)",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(145 158 171)",
      "&.Mui-focused": {
        color: "black",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
      opacity: 1,
    },
    "& .MuiInputBase-input:focus::placeholder": {
      color: "rgba(145, 158, 171, 0.8)",
    },
  };

  const buttonSx = {
    mt: 3,
    mb: 1,
    borderRadius: "12px",
    backgroundColor: "#F26E21",
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    px: 2,
    py: 2,
    minWidth: 200,
    height: "48px",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F26E21",
      boxShadow: "none",
    },
  };

  return (
    <FormContainer>
      <Box
        component="form"
        onSubmit={handleSubmit}
        autoComplete="off"
        style={{ width: "100%" }}
      >
        <Autocomplete
          id="user-autocomplete" // Unique ID for Cypress testing
          options={users}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => (
            <TextField
              {...params}
              label="User"
              autoComplete="off"
              id="user-input"
              style={inputStyle}
              sx={inputSx}
            />
          )}
          value={selectedUser}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          fullWidth
          sx={inputSx}
        />

        {selectedUser && (
          <>
            <FormControl fullWidth style={inputStyle} sx={inputSx}>
              <InputLabel id="subscription-group-label">
                Subscription Group
              </InputLabel>
              <Select
                labelId="subscription-group-label"
                id="subscription-group-input" // Unique ID for Cypress testing
                name="subscriptionGroup"
                value={userDetails.subscriptionGroup}
                onChange={handleDetailChange}
                label="Subscription Group"
                autoComplete="off"
                sx={inputSx}
              >
                <MenuItem value="trial">trial</MenuItem>
                <MenuItem value="subscriber">subscriber</MenuItem>
                <MenuItem value="admin">admin</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="affiliation-input" // Unique ID for Cypress testing
              label="Affiliation"
              name="affiliation"
              value={userDetails.affiliation}
              onChange={handleDetailChange}
              fullWidth
              autoComplete="off"
              variant="outlined"
              style={inputStyle}
              sx={inputSx}
            />
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }} >
          <Button
            variant="contained"
            color="primary"
            type="submit"

            id="submit-button"
            sx={buttonSx}
          >
            Submit
          </Button>
        </Box>
        {statusMessage && (
          <StatusMessage
            id="status-message"
            style={{
              color: statusMessage.type === "success" ? "green" : "red",
            }}
          >
            {statusMessage.text}
          </StatusMessage>
        )}
      </Box>
    </FormContainer>
  );
};

export default EditUserForm;
