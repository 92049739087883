import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import useFetchManufacturers from "../../hooks/useFetchManufacturers";
import useFetchCategories from "../../hooks/useFetchCategories";
import useAddPart from "../../hooks/useAddPart";
import { Box, Button } from "@mui/material";
import { sanitizeInput } from "../../helper/helper";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(145, 158, 171, 0.8)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 8,
    borderColor: "rgba(145, 158, 171, 0.2)",
  },
  "& .MuiInputLabel-root": {
    color: "rgba(145, 158, 171)",
    "&.Mui-focused": {
      color: "black",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "rgba(145, 158, 171, 0.8)",
    opacity: 1,
  },
  "& .MuiInputBase-input:focus::placeholder": {
    color: "rgba(145, 158, 171, 0.8)",
  },
}));

const AddFullPart = () => {
  const [formData, setFormData] = useState({
    digiKeyPartNumber: "",
    partNumber: "",
    radPart: "",
    description: "",
    manufacturer: "",
    spaceRated: "Commercial",
    category: "",
  });

  const manufacturers = useFetchManufacturers();
  const [selectedMFG, setSelectedMFG] = useState(null);

  const categories = useFetchCategories();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { addPart, statusMessage } = useAddPart();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? sanitizeInput(checked) : sanitizeInput(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      digiKeyPartNumber: formData.digiKeyPartNumber,
      partNumber: formData.partNumber,
      radPart: formData.partNumber,
      description: formData.description,
      manufacturer: selectedMFG,
      spaceRated: formData.spaceRated,
      category: selectedCategory,
    };

    addPart(payload);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CustomTextField
        label="Digi-Key Part Number"
        name="digiKeyPartNumber"
        value={formData.digiKeyPartNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <CustomTextField
        label="Part Number"
        name="partNumber"
        value={formData.partNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <CustomTextField
        label="Radiation Part"
        name="radPart"
        value={formData.radPart}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ mb: 3 }}
      />
      <Autocomplete
        id="manufacturer-autocomplete"
        options={manufacturers}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <CustomTextField {...params} label="Manufacturer" sx={{ mb: 3 }} />
        )}
        value={selectedMFG}
        onChange={(event, newValue) => setSelectedMFG(newValue)}
        fullWidth
        margin="normal"
      />
      <Autocomplete
        id="category-autocomplete"
        options={categories}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <CustomTextField {...params} label="Category" sx={{ mb: 3 }} />
        )}
        value={selectedCategory}
        onChange={(event, newValue) => setSelectedCategory(newValue)}
        fullWidth
        margin="normal"
      />
      <label style={{ black: "white" }}>
        Space Rated:
        <input
          type="checkbox"
          name="spaceRated"
          checked={formData.spaceRated}
          onChange={handleChange}
          style={{ marginLeft: "10px" }}
        />
      </label>
      <br />
      <CustomTextField
        label="Category"
        name="category"
        value={formData.category}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ mb: 3 }}
      />
      <br />
      {/* <button type="submit">Submit</button> */}

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",

            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21", // Keep the same background on hover
              boxShadow: "none", // Remove box-shadow on hover if any
            },
            "&:active": {
              backgroundColor: "#F26E21", // Keep the same background on click
              boxShadow: "none", // Remove box-shadow on click if any
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21", // Keep the same background on focus
              boxShadow: "none", // Remove box-shadow on focus if any
            },
          }}
        >
          Submit
        </Button>
      </Box>

      {statusMessage && (
        <div
          style={{ color: statusMessage.type === "success" ? "green" : "red" }}
        >
          {statusMessage.text}
        </div>
      )}
    </form>
  );
};

export default AddFullPart;
