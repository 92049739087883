import { useState, useEffect } from 'react';

const useFetchTestResult = (testResultName) => {
    const [testResult, setTestResult] = useState(null);

    console.log("Fetching test Results in useFetchTestResult")
    useEffect(() => {
        const fetchTestResult = async () => {
            try {
                console.log('Fetching test result:', testResultName); // Debugging log
                const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/fetch-testresult`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({ name: testResultName })
                });
                const data = await response.json();
                console.log('Response data:', data); // Debugging log

                if (response.ok) {
                    setTestResult(data);
                } else {
                    throw new Error(data.message || 'Failed to fetch test result');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        if (testResultName) {
            fetchTestResult();
        }
    }, [testResultName]);

    return testResult;
};

export default useFetchTestResult;
