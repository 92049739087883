import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { Element, scroller } from "react-scroll";
import Hero from "../HeroSection/Hero";
import AboutUs from "../AboutUs/AboutUs";
import Pricing from "../Pricing/Pricing";
import ContactForm from "../ContactUs/contact-us";
import FAQAccordion from "../FAQs/FAQAccordion";
import Services from "../Services/services";
import Products from "../ProductPage/Product";
import { useLocation } from "react-router-dom";

const sections = [
  { Component: Hero, name: "Hero" },
  { Component: Services, name: "Features" },
  { Component: Products, name: "Products" },
  { Component: Pricing, name: "Pricing" },
  { Component: AboutUs, name: "About Us" },
  { Component: FAQAccordion, name: "FAQ" },
  { Component: ContactForm, name: "Contact" },
];

function HomePage() {
  const location = useLocation();
  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      setTimeout(() => {
        scroller.scrollTo(hash, {
          duration: 600,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }, 500);
    }
  }, [location]);

  return sections.map(({ Component, name }, index) => {
    const isHero = name === "Hero";
    return (
      <Element name={name.replace(/\s+/g, "").toLowerCase()} key={name}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
            width: "100%",
            backgroundColor: index % 2 === 0 ? "#f9f9fa" : "#ffffff",
            py: 10,
            backgroundImage: isHero
              ? `url("https://unpkg.com/three-globe@2.31.1/example/img/night-sky.png")`
              : "none",
          }}
        >
          <Container
            sx={{
              px: { xs: 3, sm: 4 },
              transform: { lg: "scale(0.9)", xl: "scale(1)" },
            }}
            maxWidth={isHero ? false : "xl"}
          >
            <Component />
          </Container>
        </Box>
      </Element>
    );
  });
}

export default HomePage;
