import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const ManufacturerBarChart = ({ bomDetails }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (bomDetails) {
            processChartData();
        }
    }, [bomDetails]);

    const processChartData = () => {
        const manufacturerCounts = bomDetails.bom_lines.reduce((acc, line) => {
            acc[line.Manufacturer] = (acc[line.Manufacturer] || 0) + 1;
            return acc;
        }, {});

        // Convert the object to an array and sort it by count in descending order
        const sortedCounts = Object.entries(manufacturerCounts)
            .sort((a, b) => b[1] - a[1])
            .reduce((acc, [key, value]) => {
                acc.manufacturers.push(key);
                acc.counts.push(value);
                return acc;
            }, { manufacturers: [], counts: [] });

        setData([
            {
                x: sortedCounts.manufacturers,
                y: sortedCounts.counts,
                type: 'bar'
            }
        ]);
    };

    return (
        <Plot
            data={data}
            layout={{ title: 'Number of Parts by Manufacturer', xaxis: { title: 'Manufacturer' }, yaxis: { title: 'Part Count' } }}
        />
    );
};

export default ManufacturerBarChart;
