import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// Custom style using styled from MUI
const CustomButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    textTransform: 'uppercase',
    padding: '0.625rem 1.5rem',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    backgroundColor: '#F26E21',
    borderColor: '#F26E21',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#F26E21',
    },
}));

const IconButton = ({ title, icon: Icon, handleClick, ...props }) => {
    return (
        <CustomButton variant="contained" onClick={handleClick} {...props}>
            {title}
            {Icon && <Icon style={{ marginLeft: '0.5rem' }} />}
        </CustomButton>
    );
};

IconButton.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.elementType || PropTypes.bool || PropTypes.object,
    handleClick: PropTypes.func,
    ...Button.propTypes,
};

export default IconButton;