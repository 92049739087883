export const subscriptionDataMonthly = [
  {
    title: "Individual",
    description: "Full feature license for 1 user.",
    price: "500",
    monthly: "/per month",
    bgImag: "../assets/p12-1.png",
    buttonText: "Subscribe",
    bgColor: "#fff",
    text: "#313E5B",
    cardBottomColor: "#FFF",
    SubscriptionText: "#F26E21",
    features: [
      "Searchable Parts Data",
      "Analysis Features",
      "Bill of Materials Tools",
      "Additional Nucleon Tested Parts Available for Purchase"
    ],
    link: "https://buy.stripe.com/5kA3fN31gd0w2Zi9AE",
  },
  {
    title: "Team",
    tag: "Most popular",
    bgImag: "../assets/p12-1.png",
    description: "Full access for up to 10 users. Includes up to 10 hours of consulting per month.",
    price: "2,000",
    monthly: "/per month",
    buttonText: "Subscribe",
    bgColor: "#fff",
    cardBottomColor: "#FFF",
    SubscriptionText: "#F26E21",
    text: "#FFF",
    features: [
      "All features of Individual Subscription",
      "Team features such as shared Bill of Materials"
    ],
    link: "https://buy.stripe.com/6oE6rZgS6f8E8jC6ou",
  }
];





export const subscriptionDataYearly = [
  {
    title: "Individual",
    description: "Full feature license for 1 user.",
    price: "5,000",
    yearly: "/per year",

    buttonText: "Subscribe",
    bgImag: "../assets/p12-1.png",
    cardBottomColor: "#FFF",
    text: "#313E5B",
    bgColor: "#fff",
    SubscriptionText: "#F26E21",
    features: [
      "Searchable Parts Data",
      "Analysis Features",
      "Bill of Materials Tools",
      "Additional Nucleon Tested Parts Available for Purchase"
    ],
    link: "https://buy.stripe.com/6oE4jR7hwbWs57q005",
  },
  {
    title: "Team",
    tag: "Most popular",
    description: "Full access for up to 10 users. Includes up to 10 hours of consulting per month.",
    price: "24,000",
    yearly: "/per year",
    bgImag: "../assets/p12-1.png",
    buttonText: "Subscribe",
    cardBottomColor: "#FFF",
    bgColor: "#fff",
    SubscriptionText: "#F26E21",
    text: "#FFF",
    features: [
      "All features of Individual Subscription",
      "Team features such as shared Bill of Materials"
    ],
    link: "https://buy.stripe.com/28o03B7hwgcI7fyaEL",
  },
  {
    title: "Enterprise",
    description: "Enterprise Grade Support and Integrations",
    price: "200,000",
    bgImag: "../assets/p12-1.png",
    yearly: "/per year",
    buttonText: "Subscribe",
    cardBottomColor: "#FFF",
    text: "#313E5B",
    bgColor: "#fff",
    SubscriptionText: "#F26E21",
    features: [
      "Full access for up to 100 team members",
      "Premium support",
      "Pricing is customized to specific needs",
      "Contact us for more information"
    ],
    link: "https://buy.stripe.com/aEU4jReJY7Gc57qaEM",
  }
];