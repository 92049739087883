import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./Account.css"; // Make sure the path is correct
import NucleonWrapper from "../Wrapper/NucleonWrapper";

const PartDetails = () => {
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    console.log("Fetching data for account,"); // Check the parameter values
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include any credentials here if necessary
    })
      .then((response) => response.json())
      .then((data) => setAccountDetails(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <NucleonWrapper>
      <CardContent className="AccountCard-content">
        <Typography
          style={{ fontFamily: "'Poppins', sans-serif" }}
          className="AccountCard-header"
          variant="h5"
          component="h2"
        >
          Account Details
        </Typography>
        {accountDetails ? (
          <>
            <Typography
              style={{
                fontWeight: "500",
                color: "#637381",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Email: {accountDetails.Email}
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                color: "#637381",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Group: {accountDetails.Group}
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                color: "#637381",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Subscription Expiration Date: {accountDetails.SubExp}
            </Typography>
          </>
        ) : (
          <Typography
            style={{
              fontWeight: "500",
              color: "#637381",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Loading details...
          </Typography>
        )}
      </CardContent>
    </NucleonWrapper>
  );
};

export default PartDetails;
