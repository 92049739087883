const optionsTestType = [
    { value: '', label: 'Select a test type' },
    { value: 'Heavy-Ion', label: 'Heavy-Ion' },
    { value: 'Neutron', label: 'Neutron' },
    { value: 'Proton', label: 'Proton' },
    { value: 'Laser', label: 'Laser' },
    { value: 'Total-Ionizing Dose', label: 'Total-Ionizing Dose' },
    { value: 'Electron', label: 'Electron' },
    { value: 'Alpha Foil', label: 'Alpha Foil' },
    { value: 'Orbit', label: 'Orbit' },
    { value: 'Displacement Damage', label: 'Displacement Damage' },
    { value: 'Other', label: 'Other' },
];

export default optionsTestType;