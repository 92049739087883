import React, { useState } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";

export const SubmitData = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("error");

  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB file size limit

  const onChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertMessage("File size exceeds 100MB limit");
      setAlertOpen(true);
      setAlertSeverity("error");
      return;
    }
    setFile(selectedFile);
  };

  const handleClick = async (data) => {
    if (!file) {
      setAlertMessage("No file uploaded");
      setAlertOpen(true);
      setAlertSeverity("error");
      return;
    }

    if (!data.part || !data.manufacturer || !data.description || !data.price) {
      setAlertMessage("All form fields are required");
      setAlertOpen(true);
      setAlertSeverity("error");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("part", data.part);
    formData.append("manufacturer", data.manufacturer);
    formData.append("test_type", data.test_type);
    formData.append("description", data.description);
    formData.append("price", data.price);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/sellpartupload`,
        {
          method: "POST",
          body: formData,
          credentials: "include", // Include credentials if your API requires authentication
        }
      );

      if (response.ok) {
        setAlertMessage("Submission successful");
        setAlertOpen(true);
        setAlertSeverity("success");
        console.log("Success:", await response.json());
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setAlertMessage("Something went wrong");
      setAlertOpen(true);
      setAlertSeverity("error");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const StyledButton = styled(Button)(() => ({
    mt: 2,
    mb: 3,
    borderRadius: "12px",
    backgroundColor: "#F26E21",
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    px: 2,
    py: 2,
    minWidth: 200,
    height: "48px",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F26E21", // Keep the same background on hover
      boxShadow: "none", // Remove box-shadow on hover if any
    },
    "&:active": {
      backgroundColor: "#F26E21", // Keep the same background on click
      boxShadow: "none", // Remove box-shadow on click if any
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F26E21", // Keep the same background on focus
      boxShadow: "none", // Remove box-shadow on focus if any
    },
  }));

  const CustomTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black", // Set focus border color to black
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      borderColor: "rgba(145, 158, 171, 0.2)",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(145 158 171)", // Initial label color
      "&.Mui-focused": {
        color: "black", // Label color on focus
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
      opacity: 1, // Ensure full opacity
    },
    "& .MuiInputBase-input:focus::placeholder": {
      color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
    },
  }));

  return (
    <Box
      component="main"
      sx={{
        flex: 1,
        backgroundColor: "white",
        boxShadow:
          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        borderRadius: "16px",
        flexGrow: 1,
        padding: "16px 16px 16px 24px",
      }}
    >
      <Box sx={{ flex: 1, bgcolor: "#FFFFFF" }}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "0.856rem",
            color: "#637381",
          }}
        >
          Enter information about the data you would like to sell. This includes
          the title of a specific part, a specific paper, or a general topic.
          Also include in the description section a brief description of the
          data you wish to sell and in the price section provide your desired
          price. Please contact us before uploading any files that have export
          compliance restrictions.
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {file ? (
            <StyledButton onClick={() => setFile(null)}>
              <DeleteIcon sx={{ fontSize: "16px" }} />
              &nbsp;
              <Typography>{file.name}</Typography>
            </StyledButton>
          ) : (
            <StyledButton variant="contained" component="label" color="primary">
              <AddIcon /> Upload a file
              <input type="file" hidden onChange={onChange} />
            </StyledButton>
          )}
          <CustomTextField
            error={!!errors.part}
            sx={{ marginBottom: 3, mt: 3, color: "#637381" }}
            {...register("part", { required: true })}
            name="part"
            placeholder={errors.part ? "Part is a required field" : "Part"}
            label="Part"
            variant="outlined"
            fullWidth
          />
          <CustomTextField
            error={!!errors.manufacturer}
            sx={{ marginBottom: 3 }}
            {...register("manufacturer", { required: true })}
            name="manufacturer"
            placeholder={
              errors.manufacturer
                ? "Manufacturer is a required field"
                : "Manufacturer"
            }
            label="Manufacturer"
            variant="outlined"
            fullWidth
          />
          <CustomTextField
            error={!!errors.test_type}
            sx={{ marginBottom: 3 }}
            {...register("test_type", { required: true })}
            name="test_type"
            placeholder={
              errors.test_type ? "Test Type is a required field" : "Test Type"
            }
            label="Test Type"
            variant="outlined"
            fullWidth
          />
          <CustomTextField
            id="description"
            error={!!errors.description}
            {...register("description", { required: true })}
            name="description"
            sx={{ marginBottom: 3 }}
            multiline
            rows={5}
            label="Result Description"
            placeholder={
              errors.description
                ? "Result Description is a required field"
                : "Result Description"
            }
            variant="outlined"
            fullWidth
          />
          <CustomTextField
            error={!!errors.price}
            {...register("price", { required: true })}
            name="price"
            type="number"
            sx={{ color: "#637381" }}
            label="Price"
            placeholder={errors.price ? "Price is a required field" : "Price"}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <StyledButton
            onClick={handleSubmit(handleClick)}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit Request
          </StyledButton>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SubmitData;
