import { useState, useEffect } from 'react';

const useFetchUserDetails = (email) => {
    const [userDetails, setUserDetails] = useState({ subscriptionGroup: '', affiliation: '' });

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/operations/fetch-userdetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ email: email })
                });
                const data = await response.json();

                if (response.ok) {
                    setUserDetails({ subscriptionGroup: data.subscriptionGroup, affiliation: data.affiliation });
                } else {
                    throw new Error(data.message || 'Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user details:', error.message);
            }
        };

        if (email) {
            fetchUserDetails();
        }
    }, [email]);

    return userDetails;
};

export default useFetchUserDetails;
